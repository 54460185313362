<template>
  <ElPopover
      trigger="hover"
      :offset="0"
      popper-class="lang-popover"
  >
    <template #default>
      <div class="list">
        <div
          v-for="lang in languages"
          :key="lang"
          class="list__item"
          :class='{
            "list__item--active": $i18n.locale === lang
          }'
          @click="() => handleChange(lang)"
        >
          {{ lang.toUpperCase() }}
        </div>
      </div>
    </template>

    <template #reference>
      <div class="language">
        {{ $i18n.locale.toUpperCase() }}
      </div>
    </template>
  </ElPopover>
</template>
<script>
import { Popover } from 'ant-design-vue'
import {ElPopover} from "element-plus";

export default {
  components: {
    Popover,
    ElPopover
  },
  computed: {
    languages() {
      return [
        'en',
        'ru',
        'pl'
      ]
    }
  },
  methods: {
    handleChange(lang) {
      this.$i18n.locale = lang

      if(this.$route.params.lang) {
        this.$router.replace({
          ...this.$route,
          params: {
            ...this.$route.params,
            lang
          }
        })
        .then(() => {
          this.$router.go()
        })
      } else{
        this.$router.go()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.language {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 63px;
  color: #4778EE;
}

.list {
  &__item {
    background-color: white;
    transition: 0.3s all;
    padding: 8px 0px;
    text-align:center;
    border-bottom: 1px solid #E5E6EE;
    cursor: pointer;

    &--active {
      color: #4778EE;
    }

    &:hover {
      color: #4778EE;
    }

    &>:last-child {
      border-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
.lang-popover {
  width: 70px !important;
  min-width: 70px !important;
  padding: 0 !important;
}
</style>
