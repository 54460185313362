export const bus = {
  subs: {},

  on(eventName, handler) {
    this.subs[eventName] ??= []

    if(this.subs[eventName].includes(handler)) {
      return
    }
    this.subs[eventName].push(handler)
  },
  unsubscribe(eventName, handler) {
    this.subs[eventName] = this.subs[eventName].filter(item => item !== handler)
  },

  dispatch(eventName, data = null) {
    this.subs[eventName].forEach(handler => handler(data))
  }
}


export const EVENTS = {
  OPEN_AUTH: 'openAuth'
}
