import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import i18n from '@/plugins/i18n'
import Button from '@/components/ui/buttons/AppButton.vue'
import TextPrimary from '@/components/ui/typography/TextPrimary.vue'
import TextSecondary from '@/components/ui/typography/TextSecondary.vue'
import Loader from '@/components/ui/Loader.vue';
import LocaleObserver from '@/components/ui/LocaleObserver.vue';

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)

app.mixin({
  methods: {
    resolveRouteLocalized(config) {
      if(!!this.$route.params?.lang) {
        config.params ??= {}
        config.params.lang = this.$route.params?.lang
      }
      return this.$router.resolve(config)
    }
  }
})

app.component('AppButton', Button)
app.component('TextPrimary', TextPrimary)
app.component('TextSecondary', TextSecondary)
app.component('AppLoader', Loader)
app.component('LocaleObserver', LocaleObserver)

app.mount('#app')

/**
 * FIX element ui remote search select displaying keyboard on IOS
 */
let fixElSelect = function () {
  document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach((v) => {
    let elInput: HTMLInputElement = document.querySelector('.el-select[data-is-search="true"]:hover input[readonly]');
    if (elInput) {
      elInput.readOnly = false;
      elInput.blur();
      elInput.focus();
    }
  });
};
document.addEventListener("focusin", fixElSelect);
document.addEventListener("click", fixElSelect);
document.addEventListener("touchstart", fixElSelect);
/**
 * END FIX
 */
