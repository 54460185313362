<template>
  <ElTooltip>
    <Information
      class="tooltip-icon"
      :class="{
         'tooltip-icon--inherit': inheritColor
      }"
    />

    <template #content>
      <slot/>
    </template>
  </ElTooltip>
</template>
<script>
import Information from 'vue-material-design-icons/Information'
import { ElTooltip } from "element-plus";

export default {
  components: {
    Information,
    ElTooltip
  },
  props: {
    inheritColor: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-icon {
  font-size: 1.2rem;
  position: relative;
  top: 3px;
  left: 3px;
  color: lighten(grey, 25%);

  &--inherit {
    color: inherit;
  }
}
</style>
