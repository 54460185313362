<template>
  <h1 class="title">
    <slot/>
  </h1>
</template>
<style lang="scss" scoped>
.title {
  margin: 16px 0 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #272A36;
}
</style>
