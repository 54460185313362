<template>
  <div class="field-message">
    <slot/>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.field-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #8E8E93;

  margin-top: 8px;
  text-align: left;
}
</style>
