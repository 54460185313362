import {
    geoSearch,
    loadBidDeleteReasons,
    loadBidPoints,
    loadBidTypes,
    loadCargoPackingTypes,
    loadCompanyStatuses, loadCurrencies,
    loadDomains, loadPaymentMethodTypes, loadShippingTypes, loadTermPaymentTypes, loadTruckBodyTypes
} from "@/api/system";

export default {
    state: {
        domains: [],
        companyStatuses: [],
        bidDeleteReasons: [],
        bidPoints: [],
        bidTypes: [],
        cargoPackingTypes: [],
        currencies: [],
        paymentMethodTypes: [],
        shippingTypes: [],
        termPaymentTypes: [],
        truckBodyTypes: []
    },
    getters: {
        packingNameById: state => id => {
            return state.cargoPackingTypes.find(item => item.id === id)?.name ?? null
        }
    },
    mutations: {
        SET_DOMAINS: (state, data) => state.domains = data,
        SET_COMPANY_STATUSES: (state, data) => state.companyStatuses = data,
        SET_BID_DELETE_REASONS: (state, data) => state.bidDeleteReasons = data,
        SET_BID_POINTS: (state, data) => state.bidPoints = data,
        SET_BIT_TYPES: (state, data) => state.bidTypes = data,
        SET_CARGO_PACKING_TYPES: (state, data) => state.cargoPackingTypes = data,
        SET_CURRENCIES: (state, data) => state.currencies = data,
        SET_PAYMENT_METHOD_TYPES: (state, data) => state.paymentMethodTypes = data,
        SET_SHIPPING_TYPES: (state, data) => state.shippingTypes = data,
        SET_TERM_PAYMENT_TYPES: (state, data) => state.termPaymentTypes = data,
        SET_TRUCK_BODY_TYPES: (state, data) => state.truckBodyTypes = data,
    },
    actions: {
        loadAll({commit}) {
            return Promise.allSettled([
                loadDomains().then(({data}) => commit('SET_DOMAINS', data)),
                loadCompanyStatuses().then(({data}) => commit('SET_COMPANY_STATUSES', data)),
                loadBidDeleteReasons().then(({data}) => commit('SET_BID_DELETE_REASONS', data)),
                loadBidPoints().then(({data}) => commit('SET_BID_POINTS', data)),
                loadBidTypes().then(({data}) => commit('SET_BIT_TYPES', data)),
                loadCargoPackingTypes().then(({data}) => commit('SET_CARGO_PACKING_TYPES', data)),
                loadCurrencies().then(({data}) => commit('SET_CURRENCIES', data)),
                loadPaymentMethodTypes().then(({data}) => commit('SET_PAYMENT_METHOD_TYPES', data)),
                loadShippingTypes().then(({data}) => commit('SET_SHIPPING_TYPES', data)),
                loadTermPaymentTypes().then(({data}) => commit('SET_TERM_PAYMENT_TYPES', data)),
                loadTruckBodyTypes().then(({data}) => commit('SET_TRUCK_BODY_TYPES', data)),
            ])
        },
        loadAllCached({ commit }) {

        },
        geoSearch(ctx, { terms, types }) {
            return geoSearch({ terms, types })
                .then(({ data }) => data)
        }
    },
    namespaced: true
}
