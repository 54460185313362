<template>
  <transition
    :duration="500"
  >
    <div
      v-show="show"
      class="auth-modal"
    >
      <transition name="appear">
        <div
          v-show="show"
          class="substrate"
        ></div>
      </transition>

      <div class="main__wrapper">
        <transition
          name="auth-modal"
          :duration="500"
        >
          <div class="main" v-show="show">

            <BackButton
              @click="handleBack"
              v-show="stage !== 1"
            />
            <CloseButton
              class="close-button"
              @click="show = false"
            />

            <img :src="require('@/assets/logo/logo-full.svg')" class="main__logo"/>

            <div class="main__content">
              <EmailForm
                @submit="handleEmailSubmit"
                v-if="stage === 1"
                :loading="loading"
              />

              <PasswordForm
                :accountExists="emailExists"
                @submit="handlePasswordSubmit"
                @restorePassword="handleRestorePassword"
                v-if="stage === 2"
                :loading="loading"
              />
            </div>

          </div>
        </transition>
      </div>
    </div>
  </transition>
  <PasswordResetSent
    ref="passwordResetModal"
    :email="email"
  />
</template>
<script>
import EmailForm from '@/components/auth/EmailForm'
import PasswordForm from '@/components/auth/PasswordForm'
import BackButton from '@/components/auth/ui/BackButton'
import {ElNotification} from "element-plus";
import {fireLogin, fireRegistration, fireRegistrationSuccess} from "@/plugins/amplitude.js";
import PasswordResetSent from "@/components/modals/PasswordResetSent";
import {mapGetters} from "vuex";
import { SlideInOut } from 'vue3-transitions'
import CloseButton from '@/components/ui/buttons/CloseButton'
import {amplitude} from "@/plugins/amplitude.js";

function initialData() {
  return {
    show: false,

    stage: 1,

    emailExists: false,

    email: null,
    password: null,

    loading: false
  }
}

export default {
  components: {
    EmailForm,
    PasswordForm,
    BackButton,
    PasswordResetSent,
    CloseButton,

    SlideInOut
  },
  created() {
    this.resetData()

    if(this.isAuthed) {
      this.$router.replace({ name: 'home' })
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthed'])
  },
  data: initialData,
  watch: {
    stage() {
      if(this.stage === 2) {
        this.emailExists
            ? fireLogin()
            : fireRegistration()
      }
    },
    show(val) {
      if(val) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = ''
      }
    }
  },
  methods: {
    resetData() {
      Object.assign(this.$data, initialData())
    },
    handleBack() {
      this.stage = Math.min(1, this.stage - 1)
    },
    handleEmailSubmit({ email }) {
      this.email = email
      this.loading = true
      this.$store.dispatch('auth/checkIfEmailExists', { email })
          .then(res => {
            this.emailExists = false
            this.stage = 2
          })
          .catch(({ response }) => {
            if(response.status === 400) {
              this.emailExists = true
              this.stage = 2;
            } else {
              ElNotification({
                message: response?.data?.message ?? this.$t('errors.unsufficient_request'),
                type: 'error',
                duration: 3000
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    handlePasswordSubmit({ password }) {
      this.password = password
      this.loading = true

      new Promise(resolve => {
        const data = { email: this.email, password }

        if(this.emailExists) {
          return resolve(this.$store.dispatch('auth/login', data))
        }
        return resolve(this.$store.dispatch('auth/register', data))
      })
          .then(res => {
            if(!this.emailExists) {
              fireRegistrationSuccess()
            }

            this.show = false
            // reset modal data when animation of fading will finish, ~500ms now
            setTimeout(() => {
              this.resetData()
            }, 500)

            return this.$store.dispatch('users/loadMe')
          })
          .then(res => {
            amplitude.setUserId(res?.id)

            this.$store.commit('session/SET_COLLAPSED_SIDEBAR', false)

            if(this.$store.getters['users/amActive'] === false) {
              return this.$router.push({ name: 'me.fill' })
            }
            this.$router.go()
          })
          .catch(e => {
            const { response } = e

            if(response?.status === 401) {
              return ElNotification({
                message: this.$t('auth.errors.check_credentials'),
                type: 'error',
                duration: 3000,
                position: 'bottom-right'
              })
            }

            ElNotification({
              message: response?.data?.message ?? this.$t('errors.unsufficient_request'),
              type: 'error',
              duration: 3000
            })
          })
          .finally(() => {
            this.loading = false
          })
    },
    handleRestorePassword() {
      this.loading = false
      this.$store.dispatch('auth/restorePassword', {
        email: this.email
      })
          .then(res => {
            this.$refs.passwordResetModal.show()
          })
          .catch(e => {
            ElNotification({
              message: e?.response?.data?.message ?? this.$t('errors.unsufficient_request'),
              type: 'error',
              duration: 3000
            })
          })
          .finally(() => {
            this.loading = false
          })

    },
    open() {
      this.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/functions';

.substrate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(1,1,1,0.6);
  z-index: z("modal", "substrate");
  //cursor: pointer;

  //animation: 0.3s appear;
}

@media(max-width: 580px) {
  .main__wrapper {
    width: 100vw !important;
    //flex-basis: 100% !important;
  }
}



.main {
  &__wrapper {
    position: fixed;
    z-index: z("modal", "content");
    right: 0;
    top: 0;
    overflow: hidden;
    //background: yellow;
    width: 25vw;
    min-width: 340px;
    max-width: 100vw;
  }

  position: relative;

  min-height: 100vh;
  width: 100%;
  background-color: white;

  min-width: 340px;

  padding: 40px 22px 12px 22px;
  box-sizing: border-box;

  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px 25px;
    cursor: pointer;
  }

  &__logo {
    margin: auto;
  }

  &__header {
    &__title {
      margin-top: 15px !important;
    }

    &__subtitle {
      margin-top: 8px !important;
    }

    &__subtitle2 {
      margin-top: 16px !important;
    }
  }
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;

  cursor: pointer;
  transform: rotate(0deg);
}
</style>

<style lang="scss">
.auth-modal-enter-active {
  animation: 0.5s auth-modal forwards;
}
.auth-modal-leave-active {
  animation: 0.3s auth-modal reverse forwards;
}

.appear-enter-active {
  animation: 0.4s appear;
}
.appear-leave-active {
  animation: 0.4s appear reverse;
}

@keyframes auth-modal {
  0% {
    right: -500px;
  }
  100% {
    right: 0;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
