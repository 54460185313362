<template>
  <div class="chapter-title">
    <div class="chapter-title__icon">
      <slot name="icon"/>
    </div>
    <slot/>
  </div>
</template>
<style lang="scss" scoped>
.chapter-title {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 10px;
  }
}
</style>