import { checkEmailExists, createUser, passwordReset } from "@/api/users";
import {login, logout, refreshToken} from "@/api/auth";
import router from "@/router";

//
// export const useAuthStore = defineStore('auth', {
//     state: () => ({
//         token: null,
//         expires_in: null
//     }),
//     getters: {
//         isAuthed: state => !!state.token && state.token !== 'null'
//     },
//     actions: {
//       async restoreAuth() {
//         this.token = localStorage.getItem('token') ?? null
//         this.expires_in = localStorage.getItem('expires_in') ?? null
//       },
//
//       checkIfEmailExists({ commit }, { email }) {
//           return checkEmailExists({ email })
//             .then(({ data }) => data)
//       },
//       login({ commit }, params) {
//           return login(params)
//             .then(({ data }) => {
//                 commit('SET_TOKEN_INFO', {
//                     token: data.token_type.toUpperCase() + ' ' + data.access_token,
//                     expires_in: data.expires_in
//                 })
//             })
//             .catch(e => {
//                 return Promise.reject(e)
//             })
//       },
//       register({ commit }, params) {
//           return createUser({ ...params, password_confirmation: params.password })
//             .then(({ data }) => {
//                 commit('SET_TOKEN_INFO', {
//                     token: data.token_type.toUpperCase() + ' ' + data.access_token,
//                     expires_in: data.expires_in
//                 })
//
//                 return data
//             })
//
//       },
//       softLogout({ commit }) {
//           return logout()
//             .finally(() => {
//                 commit('RESET_TOKEN_INFO')
//                 commit('stockFilter/RESET', null, { root: true })
//             })
//       },
//       logout({ commit }) {
//         this.token = null
//         this.expires_in = null
//
//         commit('RESET_TOKEN_INFO')
//         commit('stockFilter/RESET', null, { root: true })
//       },
//       refreshToken({ commit }) {
//           return refreshToken()
//             .then(({ data}) => {
//               state.token =  data.token_type.toUpperCase() + ' ' + data.access_token
//               state.expires_in = data.expires_in
//             })
//             .catch(e => {
//                 return Promise.reject(e)
//             })
//
//       },
//       restorePassword({ commit }, { email }) {
//           return passwordReset({ email })
//             .then(({ data }) => data)
//       }
//     },
// })

export default {
    state: {
        token: null,
        expires_in: null
    },
    getters: {
        isAuthed: state => !!state.token && state.token !== 'null'
    },
    mutations: {
        SET_TOKEN_INFO(state, { token, expires_in }) {
            state.token = token
            state.expires_in = expires_in

            localStorage.setItem('token', token)
            localStorage.setItem('expires_in', expires_in)
        },
        RESET_TOKEN_INFO(state) {
            state.token = null
            state.expires_in = null

            localStorage.removeItem('token')
            localStorage.removeItem('expires_in')
        }
    },
    actions: {
        restoreAuth({ commit }) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem('token') ?? null
                const expires_in = localStorage.getItem('expires_in') ?? null

                if(token && expires_in) {
                    commit('SET_TOKEN_INFO', { token, expires_in })
                    resolve({ token, expires_in })
                }

                reject()
            })
        },

        checkIfEmailExists({ commit }, { email }) {
            return checkEmailExists({ email })
                .then(({ data }) => data)
        },
        login({ commit }, params) {
            return login(params)
                .then(({ data }) => {
                    commit('SET_TOKEN_INFO', {
                        token: data.token_type.toUpperCase() + ' ' + data.access_token,
                        expires_in: data.expires_in
                    })
                })
              .catch(e => {
                  return Promise.reject(e)
              })
        },
        register({ commit }, params) {
            return createUser({ ...params, password_confirmation: params.password })
              .then(({ data }) => {
                  commit('SET_TOKEN_INFO', {
                      token: data.token_type.toUpperCase() + ' ' + data.access_token,
                      expires_in: data.expires_in
                  })

                  return data
              })

        },
        softLogout({ commit }) {
            return logout()
                .finally(() => {
                    commit('RESET_TOKEN_INFO')
                    commit('stockFilter/RESET', null, { root: true })
                    commit('users/SET_ME', null, { root: true })
                })
        },
        logout({ commit }) {
            commit('RESET_TOKEN_INFO')
            commit('stockFilter/RESET', null, { root: true })
            commit('users/SET_ME', null, { root: true })
        },
        refreshToken({ commit }) {
            return refreshToken()
                .then(({ data}) => {
                    commit('SET_TOKEN_INFO', {
                        token: data.token_type.toUpperCase() + ' ' + data.access_token,
                        expires_in: data.expires_in
                    })
                })
                .catch(e => {
                //    router.replace({ name: 'auth' })

                    return Promise.reject(e)
                })

        },
        restorePassword({ commit }, { email }) {
            return passwordReset({ email })
              .then(({ data }) => data)
        }
    },
    namespaced: true
}
// const authStore = useAuthStore()
// authStore.$subscribe((mutation, state) => {
//   console.log('pin', mutation)
// })
