<template>
  <component :is="tag" class="title">
    <slot/>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h1'
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  color: #272A36;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 20px;
  text-align: left;
}
</style>
