import { createStore } from 'vuex'
import auth from './auth'
import options from './options'
import bids from './bids'
import companies from './companies'
import users from './users'
import stockFilter from './stock-filter'
import session from './session'

const PERSISTED_MODULES = [
  'stockFilter',
  'session'
]

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    options,
    bids,
    companies,
    users,
    stockFilter,
    session
  },
  plugins: [persistPlugin]
})

function persistPlugin(store) {
  const STORAGE_KEY = 'vuex-snapshot'

  function persistState(state) {
    let persistModulesState = {}

    PERSISTED_MODULES.forEach( moduleName => {
      persistModulesState[moduleName] = state[moduleName]
    })

    localStorage.setItem(STORAGE_KEY, JSON.stringify(persistModulesState))
  }

  try {
    let snapshot = JSON.parse(localStorage.getItem(STORAGE_KEY))

    if (snapshot && typeof snapshot === 'object') {
      for(let i of PERSISTED_MODULES) {
        if(snapshot[i]) {
          store.state[i] = snapshot[i]
        }
      }
    }
  } catch(e) {}

  store.subscribe((mutation, state) => {
    const moduleName = mutation.type.replace(/\/.*/, '')

    if(PERSISTED_MODULES.includes(moduleName)) {
      persistState(state)
    }
  })
}
