<template>
  <Substrate class="bid-form content">
    <div class="row">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.type') }} *
        </TextPrimary>
        <ElSelect v-model="bid_type">
          <ElOption
            v-for="item in allowedBidTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>
    </div>

    <div class="row" v-show="!isFreight">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.have_cargo.title') }}
        </TextPrimary>
        <div>
          <ElRadio v-model="ui.have_cargo" size="large" :label="true">
            {{ $t('offers.have_cargo.yes') }}
          </ElRadio>
          <ElRadio v-model="ui.have_cargo" size="large" :label="false">
            {{ $t('offers.have_cargo.no') }}
          </ElRadio>
        </div>
      </FormGroup>
    </div>

    <div class="row" v-show="!isFreight">
      <FormCard>
        <ChapterTitle>
          <template #icon>
            <img :src="require('@/assets/icons/house.svg')" alt="">
          </template>
          <TextPrimary>
            <template v-if="bid_type === BID_TYPE.RELOADING_OR_RECOUPLING">
              {{ $t('offers.fields.recoupling_or_reloading_place') }}
            </template>
            <template v-if="bid_type === BID_TYPE.RELOADING">
              {{ $t('offers.fields.reloading_place') }}
            </template>
            <template v-if="bid_type === BID_TYPE.RECOUPLING">
              {{ $t('offers.fields.recoupling_place') }}
            </template>
            *
          </TextPrimary>
        </ChapterTitle>

        <div class="card-fields-row">
          <div style="flex-grow: 1">
            <ElSelect v-model="bid_point_id" class="full-width">
              <ElOption
                v-for="item in bidPoints"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              />
            </ElSelect>

            <FieldMessage class="field-description">
              {{ $t('validation.required_field') }}
            </FieldMessage>
          </div>
          <div>
            <DateRangePicker
              class="full-width"
              v-model:value="bid_date_range"
              :disabled-date="date => date < today"
            />

            <FieldMessage class="field-description">
              {{ $t('validation.required_field') }}
            </FieldMessage>
          </div>
        </div>
      </FormCard>
    </div>

    <div class="row" v-show="isFreight">
      <FormCard>
        <ChapterTitle>
          <template #icon>
            <img :src="require('@/assets/icons/uploading.svg')" alt="">
          </template>
          <TextPrimary>
            {{ $t('offers.fields.uploading_place') }} *
          </TextPrimary>
        </ChapterTitle>

        <div class="card-fields-row">
          <div>
            <CountrySearchField
              class="full-width"
              v-model="uploading_place"
              :types="['city']"
            />

            <FieldMessage class="field-description">
              {{ $t('validation.required_field') }}
            </FieldMessage>
          </div>
          <div>
            <DateRangePicker
              class="full-width"
              v-model:value="uploading_date_range"
              :disabled-date="date => date < today"
            />

            <FieldMessage class="field-description">
              {{ $t('validation.required_field') }}
            </FieldMessage>
          </div>
          <!-- <TimePicker @change="handleUnloadingTimeChange"/> -->
        </div>
      </FormCard>
    </div>

    <div class="row" v-show="ui.have_cargo || isFreight">
      <FormCard>
        <ChapterTitle v-show="!isFreight">
          <template #icon>
            <img :src="require('@/assets/icons/truck.svg')" alt="">
          </template>
          <TextPrimary>
            {{ $t('offers.fields.my_arrival_point') }} *
          </TextPrimary>
        </ChapterTitle>
        <ChapterTitle v-show="isFreight">
          <template #icon>
            <img :src="require('@/assets/icons/unloading.svg')" alt="">
          </template>
          <TextPrimary>
            {{ $t('offers.fields.unloading_place') }} *
          </TextPrimary>
        </ChapterTitle>

        <div class="card-fields-row">
          <div>
            <CountrySearchField
              class="full-width"
              v-model="unloading_place"
              :multiple-limit="1"
              :types="['city']"
            />

<!--            <FieldMessage class="field-description">-->
<!--              {{ $t('validation.required_field') }}-->
<!--            </FieldMessage>-->
          </div>
          <div>
            <DateRangePicker
              class="full-width"
              :disabled-date="date => date < today"
              v-model:value="unloading_date_range"
            />

<!--            <FieldMessage class="field-description">-->
<!--              {{ $t('validation.required_field') }}-->
<!--            </FieldMessage>-->
          </div>
         <!-- <TimePicker @change="handleUnloadingTimeChange"/> -->
        </div>
      </FormCard>
    </div>

    <div class="row" v-show="!isFreight">
      <FormCard>
        <ChapterTitle>
          <template #icon>
            <img :src="require('@/assets/icons/globus.svg')" alt="">
          </template>
          <TextPrimary>
            {{ $t('offers.fields.your_arrival_point') }}

            <TooltipIcon placement="top" center="true">
              <center>
                <div v-html="$t('offers.fields.your_arrival_point_tooltip')"/>
              </center>
            </TooltipIcon>
          </TextPrimary>
        </ChapterTitle>

        <div class="card-fields-row">
          <div>
            <CountrySearchField
              class="full-width"
              v-model="ready_to_go"
              :selected="ready_to_go"
              :multiple-limit="15"
              multiple
            />

            <FieldMessage class="field-description" v-if="!ui.have_cargo">
              {{ $t('validation.required_field') }}
            </FieldMessage>
          </div>
        </div>
      </FormCard>
    </div>

    <div class="row">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.shipping_type') }} *
        </TextPrimary>
        <ElSelect v-model="shipping_type_id">
          <ElOption
            v-for="item in shippingTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>

      <FormGroup v-show="isFTL">
        <TextPrimary>
          {{ $t('offers.fields.vehicles_count') }} *
        </TextPrimary>
        <ElSelect v-model="trucks_count">
          <ElOption
            v-for="item in 15"
            :label="item"
            :value="item"
            :key="item"
          />
        </ElSelect>
      </FormGroup>
    </div>

    <div class="row">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.vehicle_body_type') }} *
        </TextPrimary>
        <ElSelect v-model="truck_body_type">
          <ElOption
            v-for="item in truckBodyTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>

      <FormGroup>
        <TextPrimary>
          &nbsp;
        </TextPrimary>
        <Checkbox v-model:checked="adr">
          {{ $t('offers.fields.adr') }}
        </Checkbox>
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.cargo_name') }} *
        </TextPrimary>
        <ElInput
          v-model="cargo.name"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          size="large"
          maxlength="55"
          :placeholder="$t('offers.describe_cargo')"
        />
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.weight') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.weight"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.volume') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.volume"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.packing') }} *
        </TextPrimary>
        <ElSelect v-model="cargo.cargo_packing_type">
          <ElOption
            v-for="item in cargoPackingTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.cargo_length') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.length"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.cargo_width') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.width"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.cargo_height') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.height"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
    </div>


    <div class="row">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.note') }}
        </TextPrimary>
        <ElInput
          v-model="description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          size="large"
          :maxlength="800"
          :placeholder="$t('offers.note_description')"
        />
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <div class="row-nowrap">
        <FormGroup>
          <TextPrimary>
            {{ $t('offers.fields.rate') }}
          </TextPrimary>
          <ElSelect v-model="currency_id">
            <ElOption
              v-for="item in currencies"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            />
          </ElSelect>
        </FormGroup>
        <FormGroup>
          <TextPrimary>
            &nbsp;
          </TextPrimary>
          <ElInputNumber
            v-model="rate"
            :controls="false"
            :max="999999"
          />
        </FormGroup>
      </div>
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.payment_conditions') }}
        </TextPrimary>
        <ElSelect v-model="payment_term_type">
          <ElOption
            v-for="item in termPaymentTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.payment_terms') }}
        </TextPrimary>
        <ElInputNumber
          v-model="payment_distance"
          :controls="false"
          :max="365"
        />
      </FormGroup>
    </div>

    <div class="row" v-show="ui.have_cargo">
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.payment_method') }} *
        </TextPrimary>
        <ElSelect v-model="payment_method_type_id">
          <ElOption
            v-for="item in paymentMethodTypes"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          />
        </ElSelect>
      </FormGroup>
    </div>

    <div class="row" v-show="!ui.have_cargo">
      <!--<FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.tonnage') }}
        </TextPrimary>
        <ElInputNumber
          v-model="cargo.weight"
          :controls="false"
        />
      </FormGroup>-->
      <FormGroup>
        <TextPrimary>
          {{ $t('offers.fields.volume') }}
        </TextPrimary>
        <ElInputNumber
          v-model="volume"
          :controls="false"
          :max="999999"
        />
      </FormGroup>
    </div>

    <div class="row buttons">
      <!--<AppButton mode="text">
        {{ $t('offers.actions.discard') }}
      </AppButton>-->
      <AppButton
        mode="primary"
        :disabled="loading"
        @click.native="handleSubmit"
      >
        {{ $t('offers.actions.publish') }}
      </AppButton>
    </div>
  </Substrate>
</template>
<script>
import Substrate from '@/components/ui/forms/Substrate'
import FormGroup from '@/components/ui/forms/FormGroup'
import FormCard from '@/components/ui/forms/FormCard'
import ChapterTitle from '@/components/ui/ChapterTitle'
import CountrySearchField from "@/components/ui/fields/CountrySearchField";
import TooltipIcon from "@/components/ui/TooltipIcon";
import DateRangePicker from '@/components/ui/fields/DateRangePicker'
import FieldMessage from '@/components/ui/FieldMessage'
import {ElInput, ElInputNumber, ElOption, ElRadio, ElSelect} from 'element-plus'
import {Checkbox, TimePicker} from 'ant-design-vue'
import {mapState} from 'vuex'
import {getMomentUnix} from "@/utils/number-format";
import {BID_TYPE} from "@/constants.js";
import moment from 'moment';
import { merge, difference } from 'lodash'

export default {
  components: {
    Substrate,
    FormGroup,
    FormCard,
    ChapterTitle,
    CountrySearchField,
    FieldMessage,
    ElSelect,
    ElOption,
    ElRadio,
    ElInput,
    ElInputNumber,
    DateRangePicker,
    TimePicker,
    Checkbox,
    TooltipIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    initialValues: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      bid_type: BID_TYPE.RELOADING_OR_RECOUPLING,
      bid_point_id: 1,

      bid_date_range: [],

      unloading_place: null,
      unloading_date_range: [],

      uploading_place: null,
      uploading_date_range: [],

      ready_to_go: [],
      shipping_type_id: 1,
      trucks_count: 1,
      truck_body_type: 1,
      adr: false,
      volume: 0,

      currency_id: 1,
      rate: null,
      payment_method_type_id: 1,
      payment_distance: null,
      payment_term_type: 1,

      description: '',

      cargo: {
        name: '',
        weight: null,
        length: null,
        width: null,
        height: null,
        description: '',
        volume: null,
        cargo_packing_type: 1,
      },

      /**
       * properties that not connected with bid \ cargo model. Done this way because I understood that I should have such fields after form is done and got tested
       */
      ui: {
        have_cargo: true
      }
    }
  },
  watch: {
    description(v) {
      this.cargo.description = v

    },
    bid_type(v) {
      if(v === BID_TYPE.FREIGHT) {
        this.ui.have_cargo = true
      }
    }
  },
  computed: {
    ...mapState('options', [
      'bidTypes',
      'bidPoints',
      'shippingTypes',
      'truckBodyTypes',
      'cargoPackingTypes',
      'currencies',
      'paymentMethodTypes',
      'termPaymentTypes'
    ]),
    showUnloadingTimePicker() {
      return this.unloading_start !== null && this.unloading_start
    },
    isFTL() {
      return [1].includes(this.shipping_type_id)
    },
    today() {
      return moment().startOf('day')
    },
    isFreight() {
      return this.bid_type === BID_TYPE.FREIGHT
    },
    isEdit() {
      return !!this.initialValues
    },
    allowedBidTypes() {
      if(!this.isEdit) {
        return this.bidTypes
      }
      if(this.bid_type !== BID_TYPE.FREIGHT) {
        return this.bidTypes.filter(({ id }) => id !== BID_TYPE.FREIGHT)
      }
      return this.bidTypes.filter(({ id }) => id === BID_TYPE.FREIGHT)
    },
    BID_TYPE: () => BID_TYPE
  },
  created() {
    if(this.initialValues) {
      this.fillWithInitialValues()
    }

    // Object.keys(this.initialValues).forEach(i => {
    //   if(typeof i !== 'object') {
    //     this.$data[i] = this.initialValues[i]
    //   }
    // })
  },
  methods: {
    // handleBidDateChange([ momentFrom, momentTo ]) {
    //   this.bid_point_start = getMomentUnix(momentFrom.startOf('day')) ?? null
    //   this.bid_point_end = getMomentUnix(momentTo.startOf('day')) ?? null
    // },
    // handleUnloadingDateChange([ momentFrom, momentTo ]) {
    //   this.unloading_start = getMomentUnix(momentFrom.startOf('day')) ?? null
    //   this.unloading_end = getMomentUnix(momentTo.startOf('day')) ?? null
    // },
    // handleUploadingDateChange([ momentFrom, momentTo ]) {
    //   this.uploading_start = getMomentUnix(momentFrom.startOf('day')) ?? null
    //   this.uploading_end = getMomentUnix(momentTo.startOf('day')) ?? null
    // },
    handleSubmit() {
      let form = Object.assign({}, this.$data)
      delete form.ui

      if(!this.ui.have_cargo) {
        delete form.cargo
      }

      form.ready_to_go = form.ready_to_go.map(place => ({
        place_id: place.value.split('_')[1],
        place_type: place.value.split('_')[0]
      }) )
      //
      // if(!form.ready_to_go?.length) {
      //   form.ready_to_go = null
      // }

      if(form.unloading_place && (form.bid_type === BID_TYPE.FREIGHT || this.ui.have_cargo)) {
        form.unloading_id = Number.parseInt(form.unloading_place.value.split('_')[1])
        form.unloading_type = form.unloading_place.value.split('_')[0]
      }
      delete form.unloading_place

      if(form.uploading_place) {
        form.uploading_id = Number.parseInt(form.uploading_place.value.split('_')[1])
        form.uploading_type = form.uploading_place.value.split('_')[0]
      }
      delete form.uploading_place

      if(form.bid_date_range[0]) {
        form.bid_point_start = getMomentUnix(moment(form.bid_date_range[0]).startOf('day')) ?? null
      }
      if(form.bid_date_range[1]) {
        form.bid_point_end = getMomentUnix(moment(form.bid_date_range[1]).startOf('day')) ?? null
      }
      delete form.bid_date_range

      if(form.unloading_date_range[0]) {
        form.unloading_start = getMomentUnix(moment(form.unloading_date_range[0]).startOf('day')) ?? null
      }
      if(form.unloading_date_range[1]) {
        form.unloading_end = getMomentUnix(moment(form.unloading_date_range[1]).startOf('day')) ?? null
      }
      delete form.unloading_date_range

      if(form.uploading_date_range[0]) {
        form.uploading_start = getMomentUnix(moment(form.uploading_date_range[0]).startOf('day')) ?? null
      }
      if(form.uploading_date_range[1]) {
        form.uploading_end = getMomentUnix(moment(form.uploading_date_range[1]).startOf('day')) ?? null
      }
      delete form.uploading_date_range


      if(form.bid_type === BID_TYPE.FREIGHT) {
        form.bid_point_id = null
        form.bid_point_end = null
        form.bid_point_start = null

        delete form.ready_to_go
      } else {
        delete form.uploading_place
        delete form.uploading_start
        delete form.uploading_end
      }

      if(!this.isFTL) {
        delete form.trucks_count
      }

      this.$emit('submit', form)
    },
    fillWithInitialValues() {
      const values = Object.assign({}, this.initialValues)


      this.ui.have_cargo = false
      if (values?.cargo) {
        this.ui.have_cargo = true

        Object.assign(this.$data.cargo, values.cargo)
      }
      delete values.cargo

      for(let i in this.$data) {
        if(values[i]) {
          this.$data[i] = values[i]
        }
      }

      this.$nextTick(() => {
        if(values.bid_point_start) {
          this.bid_date_range[0] = new Date(values.bid_point_start)
        }
        if(values.bid_point_end) {
          this.bid_date_range[1] = new Date(values.bid_point_end)
        }

        if(values.unloading_start) {
          this.unloading_date_range[0] = new Date(values.unloading_start)
        }
        if(values.unloading_end) {
          this.unloading_date_range[1] = new Date(values.unloading_end)
        }

        if(values.uploading_start) {
          this.uploading_date_range[0] = new Date(values.uploading_start)
        }
        if(values.uploading_end) {
          this.uploading_date_range[1] = new Date(values.uploading_end)
        }
      })




      if(values.unloading_place) {
        this.$data.unloading_place = {
          value: values?.unloading_type + '_' + values?.unloading_place?.[values?.unloading_type + '_id'],
          label: values.unloading_place?.full_name,
        }
      }
      if(values.uploading_place) {
        this.$data.uploading_place = {
          value: values?.uploading_type + '_' + values?.uploading_place?.[values?.uploading_type + '_id'],
          label: values.uploading_place?.full_name,
        }
      }

      if(values.ready_to_go_places?.length) {
        values.ready_to_go_places.forEach(place => {
          this.$data.ready_to_go.push({
            value: place.type + '_' + place?.object?.[place.type + '_id'],
            label: place?.object?.full_name
          })
        })
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding: 24px;
  & * {
    text-align: left;
  }
  .row {
    &>:not(:last-child) {
      margin-right: 20px;
    }
    &>* {
      margin-bottom: 20px;
    }
  }
}

.full-width {
  width: 100%;
}

.field-description {
  font-style: italic;
}

.card-fields-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  $gutter: 20px;
  &>* {
    flex-grow: 1;
    margin-right: $gutter;
    margin-bottom: $gutter;
  }

  margin-right: #{-$gutter};
  margin-bottom: #{-$gutter};

  &>:not(:last-child) {
  //  margin-right: 20px;
  }
}
</style>
<style lang="scss">
.bid-form {
  textarea {
    text-align: left;
    width: 350px;
    max-width: 90vw;
  }
}

.buttons {
  button {
    height: 40px;
  }
}
.row-nowrap {
  display: inline-flex;
  flex-wrap: nowrap;
}
</style>
