<template>
  <ElSelect
    ref="select"
    :placeholder="$t('ui.specify')"

    remote
    :remote-method="load"

    filterable
    clearable
    :loading="loading"
    :reserve-keyword="false"
    value-key="value"
    :multiple-limit="100"

    v-bind="selectProps"

    :no-data-text="$t('mui.autocomplete.noOptions')"
    :loading-text="$t('mui.autocomplete.loading')"

    @hook:mounted="cancelReadOnly"
    @visible-change="cancelReadOnly"
    data-is-search="true"
  >
    <ElOption
      v-for="item in filteredItems"
      :key="item.value"
      :label="item.label"
      :value="item"
    />
  </ElSelect>
</template>
<script>
import { ElSelect, ElOption, ElSelectV2 } from 'element-plus'

export default {
  components: {
    ElSelect,
    ElOption,
    ElSelectV2
  },
  data() {
    return {
      loading: false,
      items: [],
      value: []
    }
  },
  props: {
    selected: {
      type: Array,
      default: []
    },
    types: {
      type: Array,
      default: ['region', 'city', 'country']
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(
          ({ value }) => !this.selected.find(selected => selected.value === value)
      )
    },
    selectProps() {
      let { types, ...rest } = this.$props

      return { ...rest, ...this.$attrs }
    }
  },
  watch: {
    value(v) {
      // console.log(v)
      //
      // if(typeof v === 'string') {
      //   v = v.split('_')
      //
      //   return this.$emit('update:modelValue', {
      //     id: v[1],
      //     type: v[0]
      //   })
      // } else if(typeof v === 'object') {
      //   const values = Object.values(v)
      //
      //   return this.$emit('update:modelValue', values.map(item => ({
      //     id: item[1],
      //     type: item[0]
      //   })) )
      // }
      // this.$emit('update:modelValue', null)
    },
    // modelValue(v) {
    //   this.value = v
    // }
  },
  methods: {
    cancelReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
        }
      });
    },

    load(terms) {
      if(terms.length < 3) {
        this.items = []
        return
      }

      this.loading = true
      this.$store.dispatch('options/geoSearch', { terms, types: this.types })
        .then(data => {
          this.items = data.map(item => ({ value: item.id, label: item.name }))
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleClick() {
      setTimeout(() => {
        document.querySelectorAll('.el-input__inner').forEach(item => item.removeAttribute('readonly'))
        this.$nextTick(() => {
          this.$refs.select.focus()
        })
      }, 100)
    }
  }
}
</script>
