<template>
  <div class="close">
    <Close class="close__icon"/>
  </div>
</template>
<script>
import Close from 'vue-material-design-icons/Close';

export default {
  components: {
    Close
  }
}
</script>
<style lang="scss" scoped>
.close {
  border-radius: 100%;
  background-color: rgba(1,1,1,0);
  transition: 0.3s all;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 3px;
  color: #8E8E93;
  cursor: pointer;
  transform: rotate(0deg);

  &:hover {
    //background-color: rgba(1,1,1,0.23);
    //color: white;

    transform: rotate(180deg);
  }

  &__icon {
    position: relative;
    bottom: 3px;
  }
}
</style>
