import api from '@/plugins/api'

export const hideBidForMe = (id, params = {}) => api.delete(`bids/${id}/hide`, { params })
export const createBid = (params = {}) => api.post(`bid`, params)
export const getBid = (id, params = {}) => api.get(`bids/${id}`, { params })
export const getBidOpen = (id, params = {}) => api.get(`bids/${id}/open`, { params })
export const updateBid = (id, params = {}) => api.patch(`bids/${id}`, params)
export const deleteBid = (id, reason_id, params = {}) => api.delete(`bids/${id}/${reason_id}`, params)
export const getAll = (params = {}) => api.get(`bids`, { params })
export const getAllOpen = (params = {}) => api.get(`bids/open`, { params })
export const getBidMatches = (id, params = {}) => api.get(`bids/${id}/match`, { params })
export const getMyBids = (params = {}) => api.get(`bids/me`, { params })
