<template>
  <div class="form-card">
    <slot/>
  </div>
</template>
<style lang="scss">
.form-card {
  background: #FFFFFF;
  border: 1px solid #E5E6EE;
  border-radius: 4px;
  padding: 16px;

  &>:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
