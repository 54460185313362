<template>
  <div class="layout">
    <TheLandingHeader/>
    <main class="content">
      <img
        class="preview"
        :src="previewUrl"
        alt=""
      >
      <div class="description">
        <span class="description__accent">
          {{ $t('landing.free') }}
        </span>
        <span v-html="$t('landing.description')"></span>
      </div>
      <AppButton
        mode="primary"
        @click="handleRegister"
      >
        {{ $t('landing.try') }}
      </AppButton>
      <footer>
        <div class="links">
          <a href="/Roolz_Privacy_Policy.pdf" target="blank">
            {{ $t('landing.privacy') }}
          </a>

          <a href="/Roolz_Terms_of_Use.pdf" target="blank">
            {{ $t('landing.terms') }}
          </a>
        </div>
      </footer>
    </main>
  </div>
</template>
<script>
import TheLandingHeader from '@/components/layout/landing/TheLandingHeader.vue'
import { fireWelcomePage } from '@/plugins/amplitude'
import {mapGetters} from "vuex";

export default {
  components: {
    TheLandingHeader
  },
  created() {
    fireWelcomePage()
    this.setCanonical()
    this.setMetaDescription()

    if(this.isAuthed) {
      this.$router.replace({ name: 'home' })
    }
  },
  watch: {
    $route() {
      this.setCanonical()
      this.setMetaDescription()
    }
  },
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"))
  },
  computed: {
    ...mapGetters('auth', ['isAuthed']),

    previewUrl() {
      let lang = this.$i18n.availableLocales.includes(this.$i18n.locale) ? this.$i18n.locale : 'en'

      return '/landing/preview_' + lang + '.png'
    },
  },
  methods: {
    handleRegister() {
      this.$router.push(this.resolveRouteLocalized({ name: 'auth' }))
    },
    setCanonical() {
      let tag = document.querySelector('link[rel="canonical"]')
      if(!tag) {
        tag = document.createElement('link')
      }
      tag.setAttribute('rel', 'canonical')
      tag.setAttribute('href', window.location.href)

      document.querySelector('head').append(tag)
    },
    setMetaDescription() {
      let tag = document.querySelector('meta[name="description"]')
      if(!tag) {
        tag = document.createElement('meta')
      }
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', this.$t('landing.meta_description'))

      document.querySelector('head').append(tag)
    }
  }
}
</script>
<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    &>* {
      margin-bottom: 26px;
    }
  }

  .preview {
    max-height: 70vh;
    max-width: 90vw;
  }

  .description {
    color: #272A36;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;

    &__accent {
      font-weight: 800;
    }
  }

  footer {
    align-self: stretch;
    text-align: center;

    .links {
      display: flex;
      gap: 20px 64px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      a {
        display: inline-block;
        text-decoration: underline;
        color: #7F8598;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
</style>
