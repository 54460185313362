<template>
  <div class="email-form">
    <form @submit.prevent.stop="handleSubmit" class="form">
      <Title>{{ $t('auth.welcome_title') }}</Title>
      <Description>{{ $t('auth.welcome_subtitle') }}</Description>

      <div class="fields">
        <ElInput
          size="large"
          :placeholder="$t('auth.fields.email')"
          v-model="email"
          type="email"
          :autofocus="true"
          :required="true"
          ref="input"
          name="email"
          id="email"
          autocomplete="email"
        />
<!--        <FieldMessage>-->
<!--          {{ $t('auth.fields.email_hint') }}-->
<!--        </FieldMessage>-->
      </div>
      <div class="actions">
        <AppButton
          mode="primary"
          :disabled="loading"
        >
          {{ $t('auth.actions.continue') }}
        </AppButton>
      </div>
    </form>
    <div class="footer">
      {{ $t('auth.terms.agree_with') }}
      <a href="/Roolz_Terms_of_Use.pdf" target="blank">
        {{ $t('auth.terms.politics') }}
      </a>
      {{ $t('auth.terms.handling') }}
      <a href="/Roolz_Privacy_Policy.pdf" target="blank">
        {{ $t('auth.terms.terms') }}
      </a>
    </div>
  </div>
</template>
<script>
import Title from '@/components/auth/ui/Title.vue'
import Description from '@/components/auth/ui/Description.vue'
import FieldMessage from "@/components/ui/FieldMessage.vue";
import { ElInput } from 'element-plus'

export default {
  components: {
    Title,
    Description,
    FieldMessage,
    ElInput
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
    }
  },
  computed: {
    isValid() {
      return this.email?.length
    }
  },
  methods: {
    handleSubmit() {
      if(!this.isValid) {
        return
      }
      this.$emit('submit', {
        email: this.email
      })
    }
  },
  activated() {
    this.$refs.input.focus()
  }
}
</script>
<style lang="scss" scoped>
.email-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  .form {
    flex-grow: 1;
  }

  .footer {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8E8E93;
  }
}

.fields {
  margin-top: 60px;
}

.actions {
  margin-top: 40px;
}
</style>
