<template>
  <component :is="tag" class="text">
    <slot/>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'span'
    }
  }
}
</script>
<style lang="scss" scoped>
.text {
  color: #8E8E93;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
}
</style>