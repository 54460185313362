const en = {
    title: 'Free freight exchange with reloadings and recouplings through Belarus',
    landing: {
        "create_offer": "Create an offer",
        "try": "Try it now",
        "login": "Login",
        "register": "Register",
        "free": "Free",
        "description": " freight<br/> exchange with reloadings and recouplings through Belarus",
        "meta_description": "Looking for cargo or transport? It’s a free cutting-edge exchange of international freights including recouplings and reloadings through Belarus. Make your logistics better.",

        privacy: 'Privacy policy',
        terms: 'Terms of Use'
    },
    placeholders: {
        "link_expired": "Oops... Something went wrong.<br>Perhaps the link has expired.",
        "account_created": "Great! You have created your Roolz account.<br> Return to the app to continue setting up your profile.",
        "login_confirmed": "You have confirmed the sign-in to your Roolz account.<br> Return to the app to continue.",
        "email_verified": "Thank you! Your email address has been verified.",
        "password_changed": "Your password has been successfully changed!<br> Return to the app to sign in with your new password.",
        "change_password": {
            "title": "Want to change your password?",
            "new_password": "New password",
            "confirm_password": "Confirm password",
            "validation": {
                "passwords_not_match": "Please, make sure your passwords match"
            },
            "change_password": "Change password"
        },
        "account_deletion_canceled": "You have canceled your Roolz account deletion request.<br> We are happy that you decided to stay with us!",
        "email_changed": "Great! Your Roolz sign-in email has been changed.",
        "email_unsubscribe": "Unsubscribe from the current customer success mailing list of Roolz?",
        "email_unsubscribed": "Done. You've unsubscribed from the list.",
        "validation": {
            "passwords_match": "Please, make sure your passwords match",
            "password_conditions": "Password must contain at least one letter and digit and be at least 8 characters in length"
        },
        goto_app: "Go to Roolz",
        unsubscribe: 'Unsubscribe'
    },
    // auth: {
    // "welcome_title": "Welcome!",
    // "welcome_subtitle": "Sign in or sign up",
    // }
    auth: {
        "welcome_title": "Welcome!",
        "welcome_subtitle": "Sign in or sign up",
        "login_via_google": "Sign in with Google",
        "login_via_facebook": "Sign in with Facebook",
        "login_via_apple": "Sign in with Apple ID",
        "login": {
            "title": "Glad to see you again!",
            "subtitle": "Enter your account password",
            "specify_sent_code": "Enter the code we sent you to",
            "havent_received_code": "Have not received? Please, wait ",
            "email_sent": "We have sent an email with a confirmation link to the email address you provided. Please, follow the link to confirm your email.",
            "forgot_password": "Forgot your password?",
            "sms_resent": "Another SMS was sent"
        },
        registration: {
            "title": "Registration",
            "create_password": "Create a password and confirm your email address",
        },
        terms: {
            "agree_with": "By signing up and signing in to Roolz you agree to ",
            "politics": "the Privacy",
            "handling": " and Data Protection Policies and ",
            "terms": "Terms of Use"
        },
        tabs: {
            "phone_number": "Phone number",
            "email": "Email"
        },
        fields: {
            "email": "Email",
            "email_hint": "A confirmation link will be sent to your email address",
            "phone": "Phone number",
            "phone_hint": "We will send you an SMS code to the specified number",
            "phone_code": "Country selection",
            "password": "Password",
            "sms": "Code"
        },
        actions: {
            "continue": "Continue",
            "back": "Back",
            "login_via_link": "Sign in with a link",
            "register_via_link": "Sign up with a link",
            "resend_sms": "Request another SMS code",
            "goto_beginning": "Return to the beginning",
            "restore": "Reset",
            "send": "Send",
            "cancel": "Cancel",
            "ok": "Ok"
        },
        restorePasswordModal: {
            forgotPassword: "Forgot your password?",
            emailWillBeSent: "A link to reset your password will be sent to your email address {{email}}",
            restorePassword: 'Password reset',
            emailSent: "A password reset letter was sent to your email address <b>{email}</b>. <br>Follow the link in the email to reset your password.",
            cantSendMail: "Failed to send email. Please, try again later"
        },
        errors: {
            check_credentials: "Check credentials"
        },
    },
    header: {
        create_offer: 'Create an offer',
        open_profile: 'Open profile'
    },

    layout: {
        drawer: {
            stock: 'Public exchange',
            my_offers: 'My offers',
            my_company: 'My company',
            my_partners: 'My partners',
            partner_requests: 'My exchange',
            coming_soon: 'Coming soon',

            partners_requests_tooltip: 'Do you want your own freight exchange?<br>Here you can privately send and<br>receive offers from your partners',
            collapse: 'Collapse'
        },
        topMenu: {
            profile: 'My profile',
            logout: 'Sign out'
        },

        share_partners: 'Invite your colleagues and partners to Roolz'
    },
    company: {
        my_company: 'My company',
        edit: 'Edit',
        create: 'Create',
        fields: {
            name: 'Company name',
            site: 'Company website',
            email: 'Сontact e-mail',
            address: 'Registered address',
            registration_number: 'Registration number',
            unp: 'VAT or UNP (Payer’s Account Number)',
            domain: 'Field of activity',
            description: 'About company',
            verification_status: 'Verification status'
        },
        verification: {
            status: 'Verification status',
            absent: 'Unverified',
            present: 'Verified',
            request: 'Request verification'
        }
    },
    profile: {
        my_profile: 'My profile',
        edit: 'Edit',
        welcome: 'Welcome!',
        fill_profile: 'Please, complete your profile',
        contacts: 'Contacts',
        private_info: 'Personal information',
        fields: {
            phone: 'Contact number',
            email: 'Email',
            skype: 'Skype',
            first_name: 'First name',
            last_name: 'Last name',
            company: 'Company'
        },
        delete: {
            title: 'Delete account',
            confirmation: 'Are you sure you want to delete your account?',
            back: 'Cancel',
            confirm: 'Delete'
        }
    },
    offers: {
        create_offer: 'New offer',
        "edit_offer": "Edit the offer",
        details: 'Offer details',

        inactive: {
            title: 'Oops... The offer is no longer valid',
            find_more: 'Find new ones'
        },

        fields: {
            created_at: 'Created on',
            type: 'Offer type',
            intercept_point: 'Recoupling point',
            my_arrival_point: 'Where to deliver cargo',
            your_arrival_point: 'Where ready to carry',
            your_arrival_point_tooltip: 'If you want to find a cargo after your recoupling/reloading,<br>then indicate the directions where you are ready to deliver the cargo.<br>You can specify both countries as a whole and individual localities like cities.<br>You can add up to 15 destinations.',

            have_cargo: 'with cargo',
            shipping_type: 'Shipment type',
            vehicles_count: 'Number of vehicles',
            vehicle_body_type: 'Vehicle body type',
            adr: 'ADR',
            weight: 'Weight (t)',
            volume: 'Volume (m³)',
            packing: 'Packaging',
            cargo_name: 'Cargo name',
            length: 'Length (m)',
            width: 'Width (m)',
            height: 'Height (m)',
            note: 'Text comment',
            rate: 'Rate',
            payment_conditions: 'Payment conditions',
            payment_terms: 'Payment period (days)',
            payment_method: 'Payment method',
            cargo_length: 'Cargo length (m)',
            cargo_width: 'Cargo width (m)',
            cargo_height: 'Cargo height (m)',
            from: 'From',
            to: 'To',

            uploading_place: 'Pickup place',
            unloading_place: 'Unloading place',

            recoupling_place: 'Recoupling place',
            reloading_place: 'Reloading place',
            recoupling_or_reloading_place: 'Recoupling or reloading place',
        },
        view: {
            intercept_point: 'Recoupling place',
            reloading_point: 'Reloading place',
            uploading_place: 'Pickup place',
            unloading_place: 'Unloading place',
            reloading_or_intercept_point: "Reloading/recoupling place",
            my_arrival_point: 'Where to deliver cargo',
            your_arrival_point: 'Where ready to carry',
            shipping_type: 'Type',
            length: 'Length',
            width: 'Width',
            height: 'Height',
            volume: 'Volume',
            weight: 'Weight',
            type: 'Type',
            adr: 'ADR',
            packing: 'Packaging',
            vehicle_body_type: 'Vehicle body type',
            vehicles_count: 'Number of vehicles',

            matches: 'Offer matches: {count}',
            matches_open: 'View',
            full_match: 'Exact match',
            partial_match: 'Partial match',

            more: '{count} more',
            created_at: 'Created:',

            share: 'Share this offer with you colleagues and partners',

            adr_present: 'Yes',
            adr_absent: 'No'
        },
        describe_cargo: 'Please, describe the cargo',
        note_description: 'Please, describe what is important to consider',
        have_cargo: {
            title: "Do you have cargo?",
            yes: 'Yes',
            no: 'No (I offer transport only)'
        },
        delete: {
            select_reason: 'Why? Please select the deletion reason',
            confirm: 'Delete',
            cancel: 'Cancel'
        },
        actions: {
            request: 'Respond',
            not_interested: 'Not interested',
            show: 'Show',
            show_appropriate: 'Show offers',
            remove: 'Remove',
            details: 'Details',
            discard: 'Cancel',
            publish: 'Publish',
            edit: 'Edit'
        },
        filter: {
            hide_filter: 'Hide filter',
            show_filter: 'Show filter',
            clear: 'Clear',

            all: 'All',
            recoupling: 'Recouplings',
            reloading: 'Reloadings',
            freight: 'Freights',
            vehicle_body_type: 'Vehicle body type',
            adr: 'ADR',
            ftl: 'FTL',
            ltl: 'LTL',
            weight: 'Weight (t)',
            have_cargo: 'With cargo only',
            from: 'From',
            to: 'To',
            volume: 'Volume (m³)',
            intercept_point: 'Recoupling point',
            my_arrival_point: 'Where to deliver cargo',
            your_arrival_point: 'Where ready to carry',

            have_opposite_cargo: 'Only with cargo',
            full_match: 'Exact match',
            partial_match: 'Partial match',

            recoupling_place: 'Recoupling place',
            reloading_place: 'Reloading place',
            recoupling_or_reloading_place: 'Recoupling or reloading place',

            unloading_place: 'Unloading place',
            uploading_place: 'Pickup place',
        },
        contacts: {
            title: 'Contact information',
            not_specified: 'Not specified',

            non_registered: {
                title: 'In order to see the contact details, you need to register.',
                wanna_register: 'Do you want to register?',
                confirm: 'Yes'
            },
            fields: {
                name: 'Name',
                company: 'Company',
                site: 'Site',
                phone: 'Contact phone',
                email: 'Email',
                skype: 'Skype'
            },

            conditionsModal: {
                description: 'To view contact information, please:<br>1. Confirm the email address, with which you signed up for a Roolz account.<br>2. Create a profile of your company in the “My company” section.<br>Once this is done, you will be able to view contacts.<br>'
            }
        },
        conditionsModal: {
            title: 'Create an offer',
            description: 'To create an offer you need to: <br>' +
              '1. confirm your email address to which you have registered in Roolz;<br>' +
              // '2. create a profile of your company in the "My company" section. <br><br>' +
              'Once this is done, you will be able to create applications and find counter opportunities.',
            close: 'Ok'
        }
    },
    validation: {
        'password_conditions': 'Password must contain at least one letter and digit\n' +
          'and be at least 8 characters in length',
        "email": "Must be a valid email address",
        "required": "Required field",
        // TODO write something
        "min": "",
        "required_field": "Required field"
    },
    mui: {
        autocomplete: {
            loading: 'Loading...',
            noOptions: 'Nothing found'
        }
    },
    errors: {
        unsufficient_request: "Could not complete the request. Please, try again later",
        server_error: "A server error has occurred. Please, try again later",
        "Network error": "Please, check your Internet connection",
        wrong_credentials: "Invalid password"
    },
    actions: {
        save: 'Done',
        back: 'Back'
    },
    siteDescription: {
        title: 'Time to cooperate.<br>Our free freight recoupling and reloading exchange is at your service.',
        content: {
            1: 'Publish your offer for cargo or vehicle.',
            2: 'We’ll find offers that match your request.',
            3: 'You receive a new cargo in the direction in which you can work. And your cargo is delivered to where you want.',
        },
        footer: 'Checking counterparties is completely on your side.'
    },
    emailConfirmation: {
        not_confirmed_message: 'You need to confirm your Email',
        confirm: 'Confirm',

        sentModal: {
            title: 'Email Confirmation\n',
            sent: 'We have sent an email to\n\n {email}. Follow the link in the email to confirm the mail.',
        }
    },
    notifications: {
        success: 'Successful'
    },
    ui: {
        copy: 'Copy',
        copied: 'Copied!',

        specify: 'Search places...',
        select: 'Select...',

        select_dates: 'Select dates..',

        copy_link: 'Copy link'
    },
    confirmation: {
        sure: 'Are you sure?',
        yes: 'yes',
        no: 'no'
    },
    measure: {
        tons: 't',
        cubic_meters: 'm³',
        kilo: 'kg'
    },
    home_placeholders: {
        stock_not_found: 'Oops… Nothing found',
        my_bids_not_found: 'No active offers created by you',
        bid_not_found: 'Offer is no longer available'
    }
}
export{
    en
}

