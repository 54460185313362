import axios from 'axios'
import i18n from './i18n'
import store from '@/store'
import router from '@/router'

const createApiInstance = () => axios.create({
  baseURL: window.location.origin === process.env.VUE_APP_PROD_SITE_URL
    ? process.env.VUE_APP_PROD_SERVER_URL
    : process.env.VUE_APP_DEV_SERVER_URL,
  timeout: 30 * 1000
})

const instance = createApiInstance()

let isRefreshingToken = false

instance.interceptors.request.use(config => {
  config.headers['Accept-Language'] = i18n.global.locale.toUpperCase()
  config.headers['Authorization'] = store.state.auth.token ?? ''

  return config
}, error => {
  // Do something with request error
  return Promise.reject(error);
})

instance.interceptors.response.use(config => {
  return config
}, async error => {
  const {response} = error

  if (response) {
    if (response?.status === 401) {
      if (store.getters['auth/isAuthed']) {
        /**
         * Token is already refreshing
         */
        if (isRefreshingToken) {
          return Promise.reject(error)
        }
        isRefreshingToken = true

        await store.dispatch('auth/refreshToken')
          .then(res => {
            router.go()
          })
          .catch(e => {
            store.dispatch('auth/softLogout')
              .then(() => {
                // router.push({ name: 'auth' })
              })
              .catch(console.log)
          })
      }
    } else {
      // await store.dispatch('auth/logout')
      //   .then(() => {
      //     router.go()
      //   })
      //   .catch(console.log)
    }
  }

  return Promise.reject(error)
})

export default instance
