<template>
  <div class="form-group">
    <slot/>
  </div>
</template>
<style lang="scss">
.form-group {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  &>* {
    margin-bottom: 9px;
  }
}
</style>