import api from '@/plugins/api'

export const loadDomains = (params = {}) => api.get('system/companies/domains', { params })
export const loadCompanyStatuses = (params = {}) => api.get('system/companies/statuses', { params })
export const loadBidDeleteReasons = (params = {}) => api.get('system/bid-delete-reasons', { params })
export const loadBidPoints = (params = {}) => api.get('system/bid-points', { params })
export const loadBidTypes = (params = {}) => api.get('system/bid-types', { params })
export const loadCargoPackingTypes = (params = {}) => api.get('system/cargo-packing-types', { params })
export const loadCurrencies = (params = {}) => api.get('system/currencies', { params })
export const loadPaymentMethodTypes = (params = {}) => api.get('system/payment-method-types', { params })
export const loadShippingTypes = (params = {}) => api.get('system/shipping-types', { params })
export const loadTermPaymentTypes = (params = {}) => api.get('system/term-payment-types', { params })
export const loadTruckBodyTypes = (params = {}) => api.get('system/truck-body-types', { params })
export const geoSearch = (params) => api.get('system/geo-search', { params })