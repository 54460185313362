import api from '@/plugins/api'

export const getMe = () => api.get('user/me')
export const updateMe = (params = {}) => api.patch('user/me', params)
export const deleteMe = (params = {}) => api.delete('user/me', { params })
export const createUser = (params = {}) => api.post('user/', params)
export const confirmEmail = (params = {}) => api.post('user/email-confirm', params)
export const passwordReset = (params = {}) => api.get('user/password-reset', { params })
export const passwordResetFinish = (params = {}) => api.post('user/password-reset', params)
export const passwordResetCheck = (params = {}) => api.post('user/password-reset/check', params)
export const getUserById = (id, params = {}) => api.get(`user/${id}`, { params })
export const getMyCompany = (params = {}) => api.get('user/company', { params })
export const checkEmailExists = (params = {}) => api.post('user/email-check', params)
export const resendVerify = () => api.post('user/email/resend-verify')
export const emailUnsubscribe = () => api.post('user/email/unsubscribe')
