<template>
  <AppButton
    class="create-button"
    :class="{
      'create-button--accent': accent
    }"
  >
    <span class="icon">+</span>

    <span class="label">
      {{ $t('header.create_offer') }}
    </span>
  </AppButton>
</template>
<script>
export default {
  props: {
    accent: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/functions';

.create-button {
  $border-radius: 4px;

  border-radius: $border-radius !important;

  color: #4778EE;
  position: relative;
  overflow: hidden;
  transition: 0s all !important;

  .icon {
    font-size: 30px;
    color: #4778EE;
    font-weight: 300;
    position: relative;
    bottom: 2px;
    padding-left: 8px;
    z-index: z("element", "foreground");
  }
  .label {
    color: #4778EE;
    z-index: z("element", "foreground");
    font-weight: 500;
  }

  & :last-child {
    padding-left: 10px;
  }

  &--accent {
    margin-left: 16px;
    height: 40px !important;

    .icon, .label {
      color: white !important;
    }
    .icon {
      padding: 0;
    }
    //background-color: #664DFD !important;
    background: linear-gradient(314.65deg, #664DFD -0.61%, #4DC8FD 100%) !important;
    //animation: 2s ease-in pulse-scale infinite;
    //background-image: linear-gradient(314.65deg, #664DFD -0.61%, #4DC8FD 100%) !important;

    //@keyframes pulse-scale {
    //  0% {
    //    transform: scale(1);
    //  }
    //  50% {
    //    transform: scale(1.03);
    //  }
    //  100% {
    //    transform: scale(1);
    //  }
    //}

    //@keyframes pulse-rotate {
    //  0% {
    //    transform: scale(1) rotate(2deg);
    //  }
    //  50% {
    //    transform: scale(1) rotate(-2deg);
    //  }
    //  100% {
    //    transform: scale(1) rotate(0);
    //  }
    //}

    &:hover:after {
      opacity: 1;
    }

    &:after {
      z-index: z("element", "background");
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: 0.3s opacity;
      background-image: linear-gradient(10.65deg, #664DFD -0.61%, #4DC8FD 100%) !important;

      opacity: 0;
    }

    //&:before {
    //  content: '';
    //  animation: 3s blink ease-in infinite;
    //  background-image: linear-gradient(90deg, transparent 0%, #4DC8FD 48%, #4DC8FD 52%, transparent 100%);
    //  position: absolute;
    //  left: 0;
    //  bottom: 0;
    //  width: 100%;
    //  height: 400px;
    //  border-radius: $border-radius;
    //
    //  z-index: 1;
    //}
  }

}

@keyframes blink {
  0% {
  //  opacity: 0;
    transform: rotate(-10deg) translate(-145%, 20%);
  }
  100% {
    opacity: 1;
    transform: rotate(-10deg) translate(100%, 20%);
  }
}
@keyframes hover {
  0% {
    opacity: 0;
    //transform: rotate(-10deg) translate(-120%, 20%);
  }
  100% {
    opacity: 1;
    //transform: rotate(-10deg) translate(100%, 20%);
  }
}

@media(max-width: 660px) {
  .create-button {
    .label {
      display: none;
    }
    .icon {
      padding-left: 0;
    }
  }
}
</style>
