// info saved about unauthorized user

export default {
  state: () => ({
    hidden_bid_ids: [],
    collapsed_sidebar: true
  }),

  mutations: {
    ADD_HIDDEN_BID(state, { id }) {
      if(!state.hidden_bid_ids.includes(id)) {
        state.hidden_bid_ids.push(id)
      }
    },
    SET_COLLAPSED_SIDEBAR(state, val) {
      state.collapsed_sidebar = val
    }
  },

  actions: {
    restoreAll({ state }) {
      let session = JSON.parse(localStorage.getItem('session'))

      if(session && typeof session === 'object') {
        Object.keys(session).forEach(key => {
          state[key] = session[key]
        })
      }
    },
    saveAll({ state }) {
      localStorage.setItem('session', JSON.stringify(state))
    },

    async hideBid({ commit }, { id }) {
      commit('ADD_HIDDEN_BID', { id })
    }
  },

  namespaced: true
}
