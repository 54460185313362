<template>
  <div class="page">
    <div class="heading">
      <Title>
        {{ $t('offers.create_offer') }}
      </Title>
    </div>
    <BidForm
      @submit="handleSubmit"
      :loading="loading"
    />
  </div>
</template>
<script>
import Title from '@/components/ui/typography/Title'
import BidForm from '@/components/bids/BidForm'
import {ElNotification, ElDialog} from "element-plus";
import {fireCreateOffer, firePublishOffer} from "@/plugins/amplitude.js";
import {mapGetters, mapState} from "vuex";
import {prepareFormToSend} from "@/utils/forms.js";

export default {
  components: {
    Title,
    BidForm,
    ElDialog
  },
  computed: {
    ...mapGetters('users', ['amEmailVerified', 'amActive', 'amAccessedToOffers']),
    ...mapState('users', ['me'])
  },
  watch: {
    me() {
      if(!this.amAccessedToOffers) {
        return this.$router.replace({ name: 'home' })
      }
    }
  },
  created() {
    fireCreateOffer()
  },
  data() {
    return {
      modal: true,

      loading: false
    }
  },
  methods: {
    async handleSubmit(data) {
      let cargo = null
      this.loading = true

      try {
        if (data.cargo) {
          cargo = await this.$store.dispatch('bids/createCargo', prepareFormToSend(data.cargo))
        }

        let filledData = {}

        Object.keys(data).forEach(i => {
          if(!['cargo'].includes(i) && (typeof data[i] === 'boolean' || data[i])) {
            if(Array.isArray(data[i]) && !data[i].length) {
              return;
            }
            filledData[i] = data[i]
          }
        })
        if(cargo?.id) {
          filledData.cargo_id = cargo.id
        }
        // filledData.bid_point_id ??= null
        // filledData.bid_start ??= null
        // filledData.bid_end ??= null

        let bid = await this.$store.dispatch('bids/createBid', filledData)

        firePublishOffer({ offer_type: bid?.bid_type })

        this.$router.push({ name: 'bids.show', params: { id: bid.id } })
      } catch(e) {
        const { response } = e

        console.log(e)
        ElNotification({
          message: response?.data?.message ?? this.$t('errors.unsufficient_request'),
          type: 'error',
          duration: 3000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page * {
  text-align: left;
}

.heading {
  padding: 8px 24px;
  //height: 80px;
  text-align: left !important;
}
</style>
