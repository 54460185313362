<template>
  <div class="email-form">
    <form @submit.prevent.stop="handleSubmit" class="form">
      <template v-if="accountExists">
        <Title>
          {{ $t('auth.login.title') }}
        </Title>
        <Description>
          {{ $t('auth.login.subtitle') }}
        </Description>
      </template>

      <template v-if="!accountExists">
        <Title>
          {{ $t('auth.registration.title') }}
        </Title>
        <Description>
          {{ $t('auth.registration.create_password') }}
        </Description>
      </template>


      <div class="fields">

        <ElInput
          size="large"
          :placeholder="$t('auth.fields.password')"
          v-model="password"
          type="password"
          show-password
          :autofocus="true"
          :required="true"
          ref="input"
          name="password"
          autocomplete="password"
        />
        <div class="restore-password" v-if="accountExists">
          {{ $t('auth.login.forgot_password') }}
          <a
            href="#"
            @click="handleRestorePassword"
            class="restore-password__link"
          >
            {{ $t('auth.actions.restore') }}
          </a>
        </div>
      </div>
      <div class="actions">
        <AppButton
          mode="primary"
          :disabled="loading"
        >
          {{ $t('auth.actions.continue') }}
        </AppButton>
      </div>
    </form>
    <div class="footer">
      {{ $t('auth.terms.agree_with') }}
      <a href="/terms-and-conditions.html" target="blank">
        {{ $t('auth.terms.politics') }}
      </a>
      {{ $t('auth.terms.handling') }}
      <a href="/privacy-policy.html" target="blank">
        {{ $t('auth.terms.terms') }}
      </a>
    </div>
  </div>
</template>
<script>
import Title from '@/components/auth/ui/Title.vue'
import Description from '@/components/auth/ui/Description.vue'
import FieldMessage from "@/components/ui/FieldMessage.vue";
import { ElInput } from 'element-plus'

export default {
  components: {
    Title,
    Description,
    FieldMessage,
    ElInput
  },
  props: {
    accountExists: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: ''
    }
  },
  computed: {
    isValid() {
      return this.password?.length
    }
  },
  methods: {
    handleSubmit() {
      if(!this.isValid) {
        return
      }

      this.$emit('submit', {
        password: this.password
      })
    },
    handleRestorePassword() {
      this.$emit('restorePassword')
    }
  },

  activated() {
    this.$refs.input.focus()
  },
  mounted() {
    this.$refs.input.focus()
  },
}
</script>
<style lang="scss" scoped>
.email-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  .form {
    flex-grow: 1;
  }

  .footer {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8E8E93;
  }
  .restore-password {
    color: #8E8E93;
    text-align: left;

    &__link {
      font-weight: 400;
    }
  }
}

.fields {
  margin-top: 60px;
}

.actions {
  margin-top: 40px;
}
</style>
