<template>
  <ElDialog
    v-model="value"
    :title="$t('auth.restorePasswordModal.restorePassword')"
  >
    <div v-html="$t('auth.restorePasswordModal.emailSent', { email })">
    </div>
  </ElDialog>
</template>
<script>
import {ElDialog} from "element-plus";

export default {
  components: {
    ElDialog
  },
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: false
    }
  },
  methods: {
    show() {
      this.value = true
    },
    close() {
      this.value = false
    }
  }
}
</script>
