import {SHIPPING_TYPE, BID_TYPE} from "@/constants.js";

function getDefaultState() {
  return {
    bid_type: BID_TYPE.RELOADING,

    [BID_TYPE.RELOADING]: {},
    [BID_TYPE.RECOUPLING]: {},
    [BID_TYPE.FREIGHT]: {},
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    SET_BID_TYPE(state, filter) {
      state.bid_type = filter
    },
    ['SET_' + BID_TYPE.RELOADING](state, filter) {
      state[BID_TYPE.RELOADING] = filter
    },
    ['SET_' + BID_TYPE.RECOUPLING](state, filter) {
      state[BID_TYPE.RECOUPLING] = filter
    },
    ['SET_' + BID_TYPE.FREIGHT](state, filter) {
      state[BID_TYPE.FREIGHT] = filter
    },
    ['RESET_' + BID_TYPE.RELOADING](state) {
      state[BID_TYPE.RELOADING] = getDefaultState()[ BID_TYPE.RELOADING]
    },
    ['RESET_' + BID_TYPE.RECOUPLING](state) {
      state[BID_TYPE.RECOUPLING] = getDefaultState()[ BID_TYPE.RECOUPLING]
    },
    ['RESET_' + BID_TYPE.FREIGHT](state) {
      state[BID_TYPE.FREIGHT] = getDefaultState()[ BID_TYPE.FREIGHT]
    },
    RESET(state) {
      let defaultState = getDefaultState()

      for(const i in defaultState) {
        state[i] = defaultState[i]
      }
    }
  },
  actions: {

  },
  namespaced: true
}
