export const SHIPPING_TYPE = {
  FTL: 1,
  LTL: 2,
  ALL: 3
}

export const BID_TYPE = {
  RECOUPLING: 1,
  RELOADING: 2,
  RELOADING_OR_RECOUPLING: 3,
  FREIGHT: 4
}

export const MATCH_TYPE = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL'
}

export const MATCH_TYPE_SCORE = {
  FULL: 100,
  PARTIAL: 50
}

