<template>
  <div class="description">
    <slot/>
  </div>
</template>
<style lang="scss" scoped>
.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8E8E93;

}
</style>
