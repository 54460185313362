import { createCompany, updateCompany } from "@/api/companies";
import {
  checkEmailExists,
  createUser,
  deleteMe,
  getMe,
  getMyCompany,
  getUserById,
  resendVerify,
  updateMe
} from "@/api/users";
import {login, logout, refreshToken} from "@/api/auth";
import router from "@/router";

export default {
  state: {
    me: null
  },
  getters: {
    amActive(state) {
      return state.me !== null && !!state.me?.is_active
    },
    amEmailVerified(state) {
      return state.me && !!state.me.email_verified_at
    },
    me(state) {
      return state.me
    },
    // if user can create offers and see contacts of other offers
    amAccessedToOffers(state, getters, rootState) {
      return /*getters.amEmailVerified &&*/ getters.amActive // && !!rootState.companies?.my_company?.id
    }
  },
  mutations: {
    SET_ME(state, data) {
      state.me = data
    },
    PATCH_ME(state, data) {
      Object.assign(state.me, data)
    }
  },
  actions: {
    async loadMe({ commit }) {
      return getMe()
        .then(({ data }) => {
          commit('SET_ME', data)
          commit('companies/SET_MY_COMPANY', data.company, { root: true })
          return data
        })
    },
    async updateMe({ commit }, data) {
      return updateMe(data)
        .then(({ data }) => {
          commit('SET_ME', data)

          return data
        })
    },
    async deleteMe({ commit }) {
      return deleteMe()
        .then(data => {
          commit('SET_ME', null)

          return data
        })
    },
    async loadUser({ commit }, { id }) {
      return getUserById(id)
        .then(({ data }) => {
          return data
        })
    },
    async sendConfirmationEmail({ commit }) {
      return resendVerify()
        .then(({ data }) => {
          return data
        })
    }
  },
  namespaced: true
}
