import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Document from '../views/Document.vue'
import store from '@/store'
import BidsCreate from '../views/Home/Bids/Create.vue'
import {messages, default as i18n} from "@/plugins/i18n.js";

export const langPrefix = `/:lang(${Object.keys(messages).join('|')})?`

const routes = [
  {
    path: langPrefix,
    name: 'lang',
    component: Document,

    children: [
      // {
      //   path: 'auth',
      //   name: 'auth',
      //   component: () => import('../views/Auth.vue')
      // },
      {
        path: 'password-reset',
        component: () => import('../views/Placeholders/ChangePassword.vue'),
      },
      {
        path: 'password-reset/success',
        name: 'password.change.success',
        component: () => import('../views/Placeholders/PasswordChanged.vue'),
      },
      {
        path: 'email-confirmation',
        component: () => import('../views/Placeholders/EmailConfirmation.vue'),
      },
      {
        path: 'link-expired',
        name: 'link_expired',
        component: () => import('../views/Placeholders/LinkExpired.vue'),
      },
      {
        path: 'email/unsubscribe',
        name: 'email.unsubscribe',
        component: () => import('../views/Placeholders/EmailUnsubscribe.vue'),
      },
      {
        path: '',
        name: 'home',
        component: () => import('../views/Home/Home.vue'),
        redirect: { name: 'bids.stock' },

        children: [
          {
            path: '',
            // name: 'landing',
            name: 'bids.stock',
            component: () => import('../views/Home/Bids/Stock.vue'),
          },
          {
            path: 'bids/my',
            name: 'bids.my',
            component: () => import('../views/Home/Bids/My.vue'),
          },
          {
            path: 'bids/:id/matches',
            name: 'bids.matches',
            component: () => import('../views/Home/Bids/Matches.vue'),
          },
          {
            path: 'bids/create',
            name: 'bids.create',
            component: BidsCreate,
          },
          {
            path: 'bids/:id',
            name: 'bids.show',
            component: () => import('../views/Home/Bids/Show.vue'),
          },
          {
            path: 'bids/:id/edit',
            name: 'bids.edit',
            component: () => import('../views/Home/Bids/Edit.vue'),
          },
          {
            path: 'company',
            name: 'companies.my',
            component: () => import('../views/Home/Companies/My.vue'),
          },

          {
            path: 'me/fill',
            name: 'me.fill',
            component: () => import('../views/Home/Me/Fill.vue'),
          },

          {
            path: 'me',
            name: 'me',
            component: () => import('../views/Home/Me/Me.vue'),
          },
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)/',
    name: 'not-found',
    redirect: { name: 'bids.stock' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nextLocalized = () => {
    if(!to?.params?.lang && from?.params?.lang) {
      to.params ??= {}

      to.params.lang = from.params?.lang

      return next(to)
    }
    return next()
  }

  if(to.params?.lang) {
    i18n.global.locale = to.params.lang
  }


  if(to.name === 'auth' && store.getters['auth/isAuthed']) {
    return nextLocalized({ name: 'home' })
  }

  if(to.name !== 'me.fill' && !!to.matched.find(({ name }) => name === 'home')) {
    if(store?.state?.users?.me !== null && !store?.getters['users/amActive']) {
      return nextLocalized({ name: 'me.fill' })
    }
  }

  return nextLocalized()
})

export default router
