export const isProd = () => {
  return window.location.origin === process.env.VUE_APP_PROD_SITE_URL
}

export const isDev = () => {
  return window.location.origin !== process.env.VUE_APP_PROD_SITE_URL
    && !/(localhost)/.test(window.location.origin)
    && !/(192\.168)/.test(window.location.origin)
}

export const getEnvName = () => {
  if(isProd()) return 'prod'
  if(isDev()) return 'dev'

  return 'local'
}
