<template>
  <component
    :is="tag"
    class="button"
    :class='{[`button-${mode}`]: true, [`button-${size}`]: true}'
    v-bind="$attrs"
  >
    <slot/>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    mode: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  padding: 8px 12px;
  outline: none;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  border-radius: 8px;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;

  /*
  display: inline-flex;
  justify-content: center;
  align-content: center;
  */

  &>* {
    margin-right: 10px;
  }

  &>:last-child {
    margin-right: 0;
  }

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

/* Modes */
.button-primary {
  background: #4778EE;
  color: white;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem;

  &:hover {
    background: lighten(#4778EE, 10%);
  }
}
.button-text {
  background: transparent;
  color: #4778EE;

  &:hover {
    color: lighten(#4778EE, 10%);
  }
}
.button-text-muted {
  background: transparent;
  color: #8E8E93;

  &:hover {
    color: lighten(#8E8E93, 10%);
  }
}
.button-text-danger {
  background: transparent;
  color: #E23834;

  &:hover {
    color: lighten(#E23834, 10%);
  }
}
.button-danger-outline {
  background-color: transparent;
  border: 1px solid #E5E6EE;
  color: #E23834;

  &:hover {
    //background-color: lighten(#E23834, 0%);
    //color: white;
    border-color: lighten(#E23834, 15%);
  }
}
.button-primary-outline {
  background: transparent;
  border: 1px solid #E5E6EE;
  color: #4778EE;

  &:hover {
    //background-color: lighten(#4778EE, 0%);
    //color: white;
    border-color: lighten(#4778EE, 10%);
  }
}
.button-muted-outline {
  background: transparent;
  border: 1px solid #8E8E93;
  color: #8E8E93;

  &:hover {
    //background-color: lighten(#8E8E93, 0%);
    //color: white;
    border-color: lighten(#8E8E93, 10%);
  }
}
.button-muted-outline-2 {
  background: transparent;
  border: 1px solid #E5E6EE;
  color: #8E8E93;

  &:hover {
    //background-color: lighten(#E5E6EE, 0%);
    border-color: darken(#E5E6EE, 20%);
  }
}
.button-icon {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
