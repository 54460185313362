<template>
  <SemipolarSpinner
    class="spinner"
    color="#C8CBDE"
    :size="75"
  />
</template>
<script>
import { SemipolarSpinner } from 'epic-spinners'

export default {
  components: {
    SemipolarSpinner
  }
}
</script>
<style lang="scss" scoped>
.spinner {
  margin: auto;
}
</style>
