<template>
  <div>
    <nav class="header">
      <div class="header__group">
        <div class="logo">
          <a :href="siteLink">
            <img :src="require('@/assets/logo/logo-full.svg')" alt="">
          </a>
        </div>
        <div class="divider"> </div>
        <CreateButton
          class="create-button"
          @click="handleRegister"
        >
          {{ $t('landing.create_offer') }}
        </CreateButton>
      </div>

      <div class="header__group buttons">
        <Language/>

        <AppButton
          class="login-button"
          @click="handleLogin"
        >
          {{ $t('landing.login') }}
        </AppButton>
        <AppButton
          mode="primary"
          @click="handleRegister"
        >
          {{ $t('landing.register') }}
        </AppButton>
      </div>
    </nav>
    <div class="header-substrate"></div>
  </div>
</template>
<script>
import CreateButton from '@/components/ui/buttons/CreateButton';
import Language from '@/components/layout/Language'

export default {
  components: {
    CreateButton,
    Language
  },
  computed: {
    siteLink() {
      return process.env.VUE_APP_SITE_URL
    }
  },
  methods: {
    handleRegister() {
      this.$router.push({ name: 'auth' })
    },
    handleLogin() {
      this.$router.push({ name: 'auth' })
    }
  }
}
</script>
<style lang="scss" scoped>
$height: 63px;

.header-substrate {
  height: $height;
}
.header {
  position: fixed;
  width: 100vw;
  height: $height;
  border-bottom: 1px solid #E2E2E2;
  background: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &>:last-child {
    margin-right: 20px;
  }

  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .buttons {
    &>* {
      margin-right: 4px;
    }

    .login-button {
      padding-left: 26px;
      padding-right: 26px;

      height: 100%;
    }
  }
}

@media(max-width: 540px) {
  .create-button {
    display: none !important;
  }
}

@media(max-width: 330px) {
  .login-button {
    display: none !important;
  }
}

.logo {
  padding: 0 20px 0 16px;
  user-select: none;
  cursor: pointer;
}

.divider {
  display: block;
  border-right: 1px solid #E2E2E2;
  height: 32px;
  width: 1px;
}

.create-button {
  height: 100%;
}
</style>
