<template>
  <AppButton mode="text" class="back-button">
    <img :src="require('@/assets/icons/arrow-left.svg')" alt="" class="back-button__icon">
    {{ $t('actions.back') }}
  </AppButton>
</template>
<style lang="scss" scoped>
.back-button {
  color: #8E8E93 !important;

  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 25px !important;
  cursor: pointer;

  &__icon {
    padding-right: 10px;
  }
}
</style>
