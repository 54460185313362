import moment from 'moment'

export const priceFormat = (number, currencySlug) => {
    return Number(number).toLocaleString('en-US', {
        style: 'currency',
        currency: currencySlug
    })
}

const timezoneOffset = new Date().getTimezoneOffset() / -60

export const formatDate = (date) => {
    // let timezoneSign = ''
    // if(timezoneOffset > 0) {
    //     timezoneSign = '+'
    // } else if(timezoneOffset < 0) {
    //     timezoneSign = '-'
    // }

    // IF backend will
    return moment.utc(date).utcOffset(+180).format('DD MMM') //+ ` (GMT${timezoneSign}${timezoneOffset})`
}

export const dateRangeFormat = (from, to = null) => {
    return formatDate(from) + (to && from?.getTime() !== to?.getTime() ? (' - ' + formatDate(to)) : '')
}


export const getMomentUnix = moment => {
    return moment
      .startOf('day')
      .add((new Date).getTimezoneOffset() * -1, 'minutes')
      .unix()
}
