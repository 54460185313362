import { getEnvName } from '@/utils/env';

// @ts-ignore
import Amplitude from 'amplitude-js'

// @ts-ignore
export const amplitude = Amplitude.getInstance()
amplitude.init(process.env.VUE_APP_AMPLITUDE_SECRET_KEY)


const logEvent = (name, params) => {
  amplitude.logEvent(name, {
    env: getEnvName(),
    ...params
  })
}

export const fireWelcomePage = () => logEvent('Scr_welcome')
export const fireRegistration = () => logEvent('Scr_registration')
export const fireRegistrationButton = () => logEvent('Registration')
export const fireRegistrationOffer = () => logEvent('Registration_offer')
export const fireRegistrationSuccess = () => logEvent('Registration_success')
export const fireLogin = () => logEvent('Scr_login')

export const fireFillProfile = () => logEvent('Scr_profile_onboarding')
export const fireProfilePage = () => logEvent('Scr_my_company_profile')
export const fireViewMyCompany = () => logEvent('Scr_my_company_profile')
export const fireCreateCompany = () => logEvent('Create_company_profile')
export const fireEditCompany = () => logEvent('Edit_company_profile')
export const fireVerifyCompany = () => logEvent('Verify_company')

export const fireCreateOffer = () => logEvent('Create_offer')
export const firePublishOffer = () => logEvent('Publlish_offer')
export const fireMyOffers = () => logEvent('Scr_my_offers')
export const fireMyOffersMatch = () => logEvent('Scr_my_offers_match')
export const fireAllOffers = () => logEvent('Scr_all_offers')

export const fireViewOffer = params => logEvent('View_offer', params)
export const fireOfferRespond = params => logEvent('Offer_respond', params)
export const fireRemoveOffer = params => logEvent('Remove_my_offer', params)
export const fireNotInterested = params => logEvent('No_interest', params)
export const fireEditOffer = params => logEvent('EditOffer', params)
export const fireCopyShareLink = params => logEvent('copy_share_link', params)
export const fireCopyInviteLink = params => logEvent('copy_invite_link', params)

export const fireLogout = () => logEvent('Sign_out')
