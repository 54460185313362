export const pl = {
  title: 'Darmowa giełda zmiany naczep i ladunków przez Białoruś',
  landing: {
    "create_offer": "Stwórz ofertę",
    "try": "Spróbować",
    "login": "Logowanie",

    "register": "Rejestracja",
    "free": "Darmowa",
    "description": " Giełda<br/>perecepok i przeładunki przez Białoruś",
    "meta_description": "Szukasz ładunku lub transportu? Jest to bezpłatna, nowoczesna Giełda ładunków międzynarodowych, w tym przeładunków i przeładunków przez Białoruś. Usprawnij swoją logistykę.",
    privacy: 'Polityka Prywatności',
    terms: 'Warunki użytkowania'
  },
  placeholders: {
    "link_expired": "Ups! Coś poszło nie tak.<br>Być może link stracił ważność.",
    "account_created": "Świetnie! Utworzyłeś swoje konto Roolz.<br> Wróć do aplikacji, aby kontynuować konfigurowanie profilu.",
    "login_confirmed": "Potwierdziłeś logowanie do swojego konta Roolz.<br> Wróć do aplikacji, aby kontynuować.",
    "email_verified": "Dziękuję ci! Twój adres e-mail został zweryfikowany.",
    "password_changed": "Twoje hasło zostało pomyślnie zmienione!<br> Wróć do aplikacji, aby zalogować się przy użyciu nowego hasła.",
    "change_password": {
      "title": "Chcesz zmienić hasło?",
      "new_password": "Nowe hasło",
      "confirm_password": "Potwierdź hasło",
      "validation": {
        "passwords_not_match": "Upewnij się, że twoje hasła pasują"
      },
      "change_password": "Zmień hasło"
    },
    "account_deletion_canceled": "Anulowałeś usunięcie swojego konta w Roolz.<br> Cieszymy się, że zdecydowałeś się z nami zostać!",
    "email_changed": "Świetnie! Twój adres e-mail logowania Roolz został zmieniony.",
    "email_unsubscribe": "Unsubscribe from the current customer success mailing list of Roolz?",
    "email_unsubscribed": "Done. You've unsubscribed from the list.",
    "validation": {
      "passwords_match": "Upewnij się, że twoje hasła pasują",
      "password_conditions": "Hasło musi zawierać co najmniej 8 znaków. Litery i cyfry"
    },
    goto_app: "Przejdź do roolz",
    unsubscribe: 'Unsubscribe'
  },
  // auth: {
  // "welcome_title": "Witamy!!",
  // "welcome_subtitle": "Zaloguj się lub zarejestruj",
  // }
  auth: {
    "welcome_title": "Witamy!",
    "welcome_subtitle": "Zaloguj się lub zarejestruj",
    "login_via_google": "Zaloguj się przez Google",
    "login_via_facebook": "Zaloguj się przez Facebook",
    "login_via_apple": "Zaloguj się przez Apple ID",
    "login": {
      "title": "Miło cię znów widzieć!",
      "subtitle": "Wpisz hasło do swojego konta",
      "specify_sent_code": "Wpisz kod, który wysłaliśmy na",
      "havent_received_code": "Nie otrzymałeś? Zaczekaj ",
      "email_sent": "Na podany adres e-mail wysłaliśmy wiadomość e-mail. Kliknij link w e-mailu, aby zweryfikować swój adres e-mail.",
      "forgot_password": "Zapomniałeś hasła? ",
      "sms_resent": "Powtórz SMS wysłany"
    },
    registration: {
      "title": "Rejestracja",
      "create_password": "Utwórz hasło i zweryfikuj swój adres e-mail",
    },
    terms: {
      "agree_with": "Rejestrując się i logując do Roolz, wyrażasz zgodę na",
      "politics": "Polityka prywatności",
      "handling": " i przetwarzanie danych osobowych oraz ",
      "terms": "Warunki korzystania"
    },
    tabs: {
      "phone_number": "Numer telefonu",
      "email": "E-mail"
    },
    fields: {
      "email": "E-mail",
      "email_hint": "Na twój adres e-mail wyślemy e-mail z linkiem potwierdzającym",
      "phone": "Numer telefonu",
      "phone_hint": "Wyślemy ci kod SMS na podany numer",
      "phone_code": "Wybór kraju",
      "password": "Hasło",
      "sms": "Kod"
    },
    actions: {
      "continue": "Kontynuować",
      "back": "Powrót",
      "login_via_link": "Zaloguj się przez link",
      "register_via_link": "Zarejestruj się przez link",
      "resend_sms": "Wysyłać SMS-a ponownie",
      "goto_beginning": "Powrót do początku",
      "restore": "Przywrócić",
      "send": "Wysłać",
      "cancel": "Anulować",
      "ok": "Ok"
    },
    errors: {
      check_credentials: "Sprawdź poprawność wprowadzonych danych\n"
    },
    restorePasswordModal: {
      forgotPassword: "Zapomniałeś hasła?",
      emailWillBeSent: "Link do zresetowania hasła zostanie wysłany na twój adres e-mail  {{email}}",
      restorePassword: 'Odzyskiwanie hasła',
      emailSent: "E-mail został wysłany na twój adres e-mail <b>{email}</b>. <br>Kliknij link w e-mailu, aby zresetować hasło.",
      cantSendMail: "Nie udało się wysłać e-maila. Spróbuj później"
    },
  },
  header: {
    create_offer: 'Stwórz ofertę',
    open_profile: 'Otwórz Profil'
  },

  layout: {
    drawer: {
      stock: 'Giełda publiczna',
      my_offers: 'Moje oferty',
      my_company: 'Moja firma',
      my_partners: 'Moi partnerzy',
      partner_requests: 'Moja giełda',
      coming_soon: 'W rozwoju',

      partners_requests_tooltip: 'Chcesz mieć własną giełdę transportową?<br> Tutaj możesz prywatnie wysyłać i otrzymywać<br>oferty od partnerów',
      collapse: 'Zwinąć'
    },
    topMenu: {
      profile: 'Mój profil',
      logout: 'Wyjście'
    },

    share_partners: 'Zaproś swoich współpracowników i partnerów do Roolz'
  },
  company: {
    my_company: 'Moja firma',
    edit: 'Edytować',
    create: 'Utworzyć',
    fields: {
      name: 'Nazwa firmy',
      site: 'Strona internetowa firmy',
      email: 'Kontaktowy adres e-mail',
      address: 'Adres rejestracji',
      registration_number: 'Numer rejestracyjny',
      unp: 'VAT lub UNP (numer konta płatnika)',
      domain: 'Pole aktywności',
      description: 'O firmie',
      verification_status: 'Stan weryfikacji'
    },
    verification: {
      status: 'Stan weryfikacji',
      absent: 'Niesprawdzony',
      present: 'Zweryfikowany',
      request: 'Złóż wniosek o weryfikację'
    }
  },
  profile: {
    my_profile: 'Mój profil',
    edit: 'Edytować',
    welcome: 'Witamy!!',
    fill_profile: 'Uzupełnij swój profil',
    contacts: 'Kontakty',
    private_info: 'Informacje osobiste',
    fields: {
      phone: 'Numer kontaktowy',
      email: 'E-mail',
      skype: 'Skype',
      first_name: 'Imię',
      last_name: 'Nazwisko',
      company: 'Firma'
    },
    delete: {
      title: 'Usuń konto',
      confirmation: 'Czy na pewno chcesz usunąć swoje konto?',
      back: 'Anulować',
      confirm: 'Usunąć'
    }
  },
  offers: {
    create_offer: 'Nowa oferta',
    "edit_offer": "Redagować ofertę",
    details: 'Szczegóły oferty',

    inactive: {
      title: 'UPS... Oferta nie jest już ważna',
      find_more: 'Znajdź nowe'
    },

    fields: {
      created_at: 'Data utworzenia:',
      type: 'Rodzaj oferty',
      intercept_point: 'Miejsce zmiany naczepy',
      my_arrival_point: 'Gdzie dostarczyć ładunek',
      your_arrival_point: 'Gdzie gotowy wyruszyć?',
      your_arrival_point_tooltip: 'Jeśli chcesz znaleźć ładunek po wymianie ładunku/naczepy,<br>wskaż kierunki, w których jesteś gotowy do dostarczenia ładunku.<br>Możesz określić zarówno kraje jako całość, jak i poszczególne miejscowości,takie jak miasta.<br>Możesz dodać maksymalnie 15 miejsc docelowych.',
      have_cargo: 'Mam ładunek',
      shipping_type: 'Rodzaj transportu',
      vehicles_count: 'Liczba samochodów',
      vehicle_body_type: 'Typ nadwozia ciężarówki',
      adr: 'ADR',
      weight: 'Waga (t)',
      volume: 'Pojemność (m3)',
      packing: 'Opakowanie',
      cargo_name: 'Nazwa ładunku',
      length: 'Długość (m)',
      width: 'Szerokość (m)',
      height: 'Wysokość (m)',
      note: 'Komentarz tekstowy',
      rate: 'Cena',
      payment_conditions: 'Warunki płatności',
      payment_terms: 'Termin płatności (dni)',
      payment_method: 'Metoda płatności',
      cargo_length: 'Długość ładunku (m)',
      cargo_width: 'Szerokość ładunku (m)',
      cargo_height: 'Wysokość ładunku (m)',
      from: 'Od',
      to: 'Do',

      recoupling_place: 'Miejsce zmiany naczepy',
      reloading_place: 'Miejsce zmiany obciążenia',
      recoupling_or_reloading_place: 'Miejsce zmiany naczepy lub obciążenia',

      uploading_place: 'Miejsce Ładowania',
      unloading_place: 'Miejsce rozładunku',
    },
    view: {
      intercept_point: 'Miejsce zmiany naczepy',
      reloading_point: 'Miejsce zmiany ladunku',
      uploading_place: 'Miejsce Ładowania',
      unloading_place: 'Miejsce rozładunku',
      reloading_or_intercept_point: "Miejsce zmiany ladunku/naczepy",
      my_arrival_point: 'Gdzie dostarczyć ładunek',
      your_arrival_point: 'Gdzie gotowy wyruszyć',
      shipping_type: 'Rodzaj',
      length: 'Długość',
      width: 'Szerokość',
      height: 'Wysokość',
      volume: 'Pojemność',
      weight: 'Waga',
      type: 'Rodzaj',
      adr: 'ADR',
      packing: 'Opakowanie',
      vehicle_body_type: 'Typ nadwozia ciężarówki',
      vehicles_count: 'Liczba samochodów',

      matches: 'Odpowiednie oferty: {count}',
      matches_open: 'Zobacz',
      full_match: 'Dokładne dopasowanie',
      partial_match: 'Częściowe dopasowanie',

      more: 'Jeszcze {count}',
      created_at: 'Utworzono ',

      share: 'Podziel się tą ofertą z kolegami i partnerami',

      adr_present: 'Tak',
      adr_absent: 'Nie'
    },
    describe_cargo: 'Opisz ładunek',
    note_description: 'Opisz, co jest ważne do rozważenia',
    have_cargo: {
      title: "Masz ładunek?",
      yes: 'Tak',
      no: 'Nie'
    },
    delete: {
      select_reason: 'Wybierz przyczynę usunięcia',
      confirm: 'usunąć',
      cancel: 'odwołanie'
    },
    actions: {
      request: 'Odpowiadać',
      not_interested: 'Nie zainteresowany',
      show: 'Zobacz',
      show_appropriate: 'Zobacz oferty',
      remove: 'Usunąć',
      details: 'Detale',
      discard: 'Anulować',
      publish: 'Publikować',
      edit: 'Edytować'
    },
    filter: {
      hide_filter: 'Ukryj filtr',
      show_filter: 'Pokaż filtr',
      clear: 'Oczyścić',

      all: 'Wszystko',
      recoupling: 'Zmiany naczepy',
      reloading: 'Zmiany ladunku',
      freight: 'Fracht',
      vehicle_body_type: 'Typ nadwozia ciężarówki',
      adr: 'ADR',
      ftl: 'FTL',
      ltl: 'LTL',
      weight: 'Waga (t)',
      have_cargo: 'Tylko z ładunkiem',
      from: 'Od',
      to: 'Do',
      volume: 'Pojemność (m3)',
      intercept_point: 'Miejsce zmiany naczepy',
      my_arrival_point: 'Gdzie dostarczyć ładunek',
      your_arrival_point: 'Gdzie gotowy wyruszyć',

      uploading_place: 'Miejsce Ładowania',
      unloading_place: 'Miejsce rozładunku',

      have_opposite_cargo: 'Tylko z nadjeżdżającym ładunkiem',
      full_match: 'Dokładne dopasowanie',
      partial_match: 'Częściowe dopasowanie',

      recoupling_place: 'Miejsce zmiany naczepy',
      reloading_place: 'Miejsce zmiany obciążenia',
      recoupling_or_reloading_place: 'Miejsce zmiany naczepy lub obciążenia',
    },
    contacts: {
      title: 'Oferta kontakt',
      not_specified: 'Nie podano',

      non_registered: {
        title: 'Aby zobaczyć dane kontaktowe, musisz się zarejestrować.',
        wanna_register: 'Chcesz się zarejestrować?',
        confirm: 'Tak'
      },
      fields: {
        name: 'Nazwa',
        company: 'Przedsiębiorstwo',
        site: 'Miejsce',
        phone: 'Telefon kontaktowy',
        email: 'Email',
        skype: 'Skype'
      },

      conditionsModal: {
        description: 'Aby przeglądać kontakty, musisz:<br>1. Potwierdź swoją pocztę rejestracyjną Roolz;<br>2. utwórz profil swojej firmy w sekcji “Moja firma”.<br>Gdy to zrobisz, będziesz mógł przeglądać kontakty.<br>'
      }
    },
    conditionsModal: {
      title: 'Stwórz ofertę',
      description: 'Aby stworzyć ofertę musisz: <br>' +
        '1. potwierdź swój adres e-mail, na który zarejestrowałeś się w Roolz;<br>' +
        // '2. stwórz profil swojej firmy w sekcji "Moja Firma" <br><br>' +
        'Gdy to zrobisz, będziesz mógł tworzyć aplikacje i znajdować liczniki.',
      close: 'Jest zrozumiałe'
    }
  },
  siteDescription: {
    title: 'Czas na współpracę.<br>Nasza darmowa wymiana ofert zmiany naczepy i zmiany ladunku jest do twojej dyspozycji.',
    content: {
      1: 'Opublikuj swoją ofertę na ładunek lub pojazd.',
      2: 'Znajdziemy oferty pasujące do twojego zapytania.',
      3: 'Otrzymujesz nowy ładunek w kierunku, w którym możesz pracować. A twój ładunek zostanie dostarczony tam, gdzie chcesz.',
    },
    footer: 'Sprawdzanie kontrahentów jest całkowicie po twojej stronie.'
  },

  validation: {
    'password_conditions': 'Hasło musi zawierać co najmniej jedną literę i cyfrę\n' +
      'i mieć co najmniej 8 znaków',
    "email": "Musi być prawidłowym adresem e-mail",
    "required": "Wymagane do wypełnienia",
    // TODO write something
    "min": "",
    "required_field": "Pole obowiązkowe"
  },
  mui: {
    autocomplete: {
      loading: 'Ładowanie...',
      noOptions: 'Nic nie znaleziono'
    }
  },
  errors: {
    unsufficient_request: "Nie udało się zrealizować żądania. Spróbuj później",
    server_error: "Na serwerze wystąpił błąd. Spróbuj później",
    "Network error": "Sprawdź swoje połączenie z internetem",
    wrong_credentials: "Nieprawidłowe hasło"
  },
  actions: {
    save: 'Zrobione',
    back: 'Powrót'
  },
  emailConfirmation: {
    not_confirmed_message: 'Musisz potwierdzić swój adres Email',
    confirm: 'Potwierdzić',

    sentModal: {
      title: 'Potwierdzenie poczty',
      sent: 'Wysłaliśmy maila na Twoja pocztę\n\n{email} kliknij link w wiadomości e-mail, aby potwierdzić pocztę.',
    }
  },
  notifications: {
    success: 'sukcesy'
  },
  ui: {
    copy: 'Kopiować',
    copied: 'Skopiowane!',

    specify: 'Wprowadzić...',
    select: 'Wybierz...',

    select_dates: 'Wybierz daty..',

    copy_link: 'Kopiuj link'
  },
  confirmation: {
    sure: 'Jesteś pewien?',
    yes: 'Tak',
    no: 'Nie'
  },
  measure: {
    tons: 't',
    cubic_meters: 'm³',
    kilo: 'kg'
  },
  home_placeholders: {
    stock_not_found: 'Ups… Nic nie znaleziono',
    my_bids_not_found: 'Brak aktywnych ofert utworzonych przez ciebie',
    bid_not_found: 'Oferta nie jest już dostępna'
  }
}


