import { createBid, deleteBid, getAll, getAllOpen, getBid, getBidOpen, getBidMatches, getMyBids, hideBidForMe, updateBid } from "@/api/bids";
import { createCargo, editCargo, deleteCargo } from "@/api/cargo";

export default {
    state: {
        // hidden bid ids (needed for unauthorized users)
        hidden: []
    },
    getters: {},
    mutations: {

    },
    actions: {
        loadMyBids(ctx, params = {}) {
            return getMyBids(params)
                .then(({ data }) => data)
        },
        loadBids({ rootGetters }, params = {}) {
            return Promise.resolve(rootGetters['auth/isAuthed']
                  ? getAll(params)
                  : getAllOpen(params)
            )
              .then(({ data }) => data)
        },
        loadBidMatches(ctx, { id, ...params }) {
            return getBidMatches(id, params)
                .then(({ data }) => data)
        },
        createCargo(ctx, params) {
            return createCargo(params)
                .then(({ data }) => data)
        },
        createBid(ctx, params) {
            return createBid(params)
                .then(({ data }) => data)
        },
        editBid(ctx, { id, ...params }) {
            return updateBid(id, params)
              .then(({ data }) => data)
        },
        editCargo(ctx, { id, ...params }) {
            return editCargo(id, params)
              .then(({ data }) => data)
        },
        deleteCargo(ctx, { id }) {
            return deleteCargo(id)
              .then(({ data }) => data)
        },
        deleteBid(ctx, { id, reason_id }) {
            return deleteBid(id, reason_id)
              .then(({ data }) => {
                  return data
              })
        },
        hideBid(ctx, { id }) {
          return hideBidForMe(id)
            .then(({ data }) => data)
        },
        loadBid({ rootGetters }, { id, ...rest }) {
            return Promise.resolve(rootGetters['auth/isAuthed']
                ? getBid(id, rest)
                : getBidOpen(id, rest)
            )
              .then(({ data }) => data)
        }
    },
    namespaced: true
}
