<template>
  <slot :key="$i18n.locale"/>
</template>
<script>
export default {
  mounted() {

  }
}
</script>
