import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { ru } from '@/locales/ru'
import { en } from '@/locales/en'
import { pl } from '@/locales/pl'
import moment from 'moment'
import router from "../router";

export const messages = { ru, en, pl }
const fallbackLocale = 'en'

function getBrowserLang() {
    let browserLang = (navigator.language || navigator.userLanguage).replace(/\-.*/, '')

    return Object.keys(messages).includes(browserLang) ? browserLang : fallbackLocale
}

const locale = localStorage.getItem('lang') ?? getBrowserLang()

const i18n = createI18n({
    locale,
    fallbackLocale,
    messages
})
moment.locale(locale)


export default i18n
