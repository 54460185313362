import { createCompany, updateCompany } from "@/api/companies";
import { checkEmailExists, createUser, getMyCompany } from "@/api/users";
import {login, logout, refreshToken} from "@/api/auth";
import router from "@/router";

export default {
  state: {
    my_company: null
  },
  getters: {

  },
  mutations: {
    SET_MY_COMPANY(state, data) {
      state.my_company = data
    }
  },
  actions: {
    async getMyCompany({ commit }) {
      return getMyCompany()
        .then(({ data }) => {
          commit('SET_MY_COMPANY', data)

          return data
        })
    },
    async createCompany({ commit,  }, data) {
      return createCompany(data)
        .then(({ data }) => {
          commit('SET_MY_COMPANY', data)

          return data
        })
    },
    async updateCompany({ commit }, { id, ...data }) {
      return updateCompany(id, data)
        .then(({ data }) => {
          commit('SET_MY_COMPANY', data)

          return data
        })
    },

  },
  namespaced: true
}
