export const ru = {
  title: 'Бесплатная биржа грузов, перецепок и перегрузок через Беларусь',
  landing: {
    "create_offer": "Создать предложение",
    "try": "Попробовать",
    "login": "Вход",
    "register": "Регистрация",
    "free": "Бесплатная",
    "description": " биржа<br/>перецепок и перегрузок через Беларусь",
    "meta_description": "Ищете грузы и транспорт? Международные перевозки, перецепки и перегрузы удобно, быстро и бесплатной на бирже Roolz - эффективная логистика.",
    privacy: 'Политика конфиденциальности',
    terms: 'Условия использования'
  },
  placeholders: {
    "link_expired": "Что-то пошло не так.<br>Возможно, срок действия ссылки истек.",
    "account_created": "Поздравляю! Вы создали аккаунт в Roolz.<br> Вернитесь в приложение, чтобы создать ваш профиль.",
    "login_confirmed": "Вы успешно подтвердили вход в ваш аккаунт.<br> Вернитесь в приложение, чтобы продолжить.",
    "email_verified": "Спасибо! Ваша почта успешно подтверждена.",
    "password_changed": "Пароль успешно сменен!<br> Вернитесь в приложение, чтобы войти с новым паролем.",
    "change_password": {
      "title": "Готовы сменить пароль?",
      "new_password": "Создайте новый пароль",
      "confirm_password": "Подтвердите пароль",
      "validation": {
        "passwords_not_match": "Пароли не совпадают"
      },
      "change_password": "Сменить пароль"
    },
    "account_deletion_canceled": "Вы успешно отменили удаление своего аккаунта.<br> Мы очень рады, что вы остались с нами!",
    "email_changed": "Вы успешно сменили Email в качестве метода авторизации в Roolz!",
    "email_unsubscribe": "Unsubscribe from the current customer success mailing list of Roolz?",
    "email_unsubscribed": "Done. You've unsubscribed from the list.",
    "validation": {
      "passwords_match": "Пароли не совпадают",
      "password_conditions": "Пароль должен состоять минимум из 8 символов. Букв и цифр"
    },
    goto_app: "Перейти в Roolz",
    unsubscribe: 'Unsubscribe'
  },
  auth: {
    "welcome_title": "Добро пожаловать!",
    "welcome_subtitle": "Войдите или зарегистрируйтесь",

    "login_via_google": "Войти через Google",
    "login_via_facebook": "Войти через Facebook",
    "login_via_apple": "Войти через Apple ID",

    "login": {
      "title": "Рады вас видеть снова!",
      "subtitle": "Введите пароль от вашего аккаунта",
      "specify_sent_code": "Введите код, который мы отправили вам на",
      "havent_received_code": "Не получили? Подождите ",
      "email_sent": "Мы отправили письмо на указанный Email. Перейдите по ссылке в письме, чтобы подтвердить Email.",
      "forgot_password": "Забыли пароль? ",
      "sms_resent": "Повторная смс отправлена"
    },
    tips: {
      '1': 'Пора кооперироваться.<br/>Спасем цепочку логистики вместе.'
    },
    registration: {
      "title": "Регистрация",
      "create_password": "Создайте пароль и подтвердите вашу почту",
    },

    terms: {
      "agree_with": "При регистрации и авторизации в Roolz, вы соглашаетесь с ",
      "politics": "Политикой конфиденциальности",
      "handling": "и ",
      "terms": "Условиями пользования"
    },
    tabs: {
      "phone_number": "Номер телефона",
      "email": "Email"
    },
    fields: {
      "email": "Email",
      "email_hint": "Мы отправим письмо с ссылкой подтверждения на вашу почту",

      "phone": "Номер телефона",
      "phone_hint": "Мы отправим вам SMS код на указанный номер",

      "phone_code": "Выбор страны",

      "password": "Пароль",
      "sms": "Код"
    },
    errors: {
      check_credentials: "Проверьте правильность введенных данных"
    },

    actions: {
      "continue": "Продолжить",
      "back": "Назад",
      "login_via_link": "Войдите через ссылку",
      "register_via_link": "Зарегистрироваться через ссылку",
      "resend_sms": "Отправить SMS повторно",
      "goto_beginning": "Вернуться в начало",
      "restore": "Восстановить",
      "send": "Отправить",
      "cancel": "Отмена",
      "ok": "Хорошо"
    },
    restorePasswordModal: {
      forgotPassword: "Забыли пароль?",
      emailWillBeSent: "На ваш Email адрес {{email}} будет отправлено письмо с возможностью смены пароля",

      restorePassword: 'Восстановление пароля',
      emailSent: "На ваш Email <b>{email}</b> отправлено письмо.<br>Пройдите по ссылке в письме для восстановления пароля.",
      cantSendMail: "Не удалось отправить email. Попробуйте позже"
    },
  },

  header: {
    create_offer: 'Создать предложение',
    open_profile: 'Открыть профиль'
  },

  layout: {
    drawer: {
      stock: 'Публичная биржа',
      my_offers: 'Мои предложения',
      my_company: 'Моя компания',
      my_partners: 'Мои партнеры',
      partner_requests: 'Моя биржа',

      coming_soon: 'В разработке',

      partners_requests_tooltip: 'Хотите вашу собственную биржу?<br>Здесь вы сможете приватно отправлять<br>и принимать заявки от ваших контрагентов.',
      collapse: 'Свернуть'
    },
    topMenu: {
      profile: 'Мой профиль',
      logout: 'Выход'
    },

    share_partners: 'Приглашайте ваших коллег и партнеров в Roolz'
  },

  company: {
    my_company: 'Моя компания',
    edit: 'Редактировать',
    create: 'Создать',

    fields: {
      name: 'Название компании',
      site: 'Сайт компании',
      email: 'Контактный email',
      address: 'Адрес регистрации',
      registration_number: 'Номер регистрации',
      unp: 'VAT или УНП',
      domain: 'Сфера деятельности',
      description: 'О компании',
      verification_status: 'Статус верификации'
    },

    verification: {
      status: 'Статус верификации',

      absent: 'Отсутствует',
      present: 'Верифицирован',

      request: 'Податься на верификацию'
    }
  },
  profile: {
    my_profile: 'Мой профиль',
    edit: 'Редактировать',

    welcome: 'Добро пожаловать!',
    fill_profile: 'Заполните свой профиль',

    contacts: 'Контакты',
    private_info: 'Личная информация',

    fields: {
      phone: 'Контактный телефон',
      email: 'Email',
      skype: 'Skype',
      first_name: 'Имя',
      last_name: 'Фамилия',

      company: 'Компания'
    },
    delete: {
      title: 'Удалить аккаунт',
      confirmation: 'Вы уверены что хотите удалить аккаунт?',

      back: 'Отмена',
      confirm: 'Удалить'
    }
  },

  offers: {
    create_offer: 'Новое предложение',
    "edit_offer": "Редактировать предложение",
    details: 'Детали предложения',

    inactive: {
      title: 'Упс... Предложение уже неактивно',
      find_more: 'Найти новые'
    },

    fields: {
      created_at: 'Дата создания',
      type: 'Тип предложения',
      intercept_point: 'Место перецепки',
      my_arrival_point: 'Куда доставить груз',
      your_arrival_point: 'Куда готовы ехать',
      your_arrival_point_tooltip: 'Если вы хотите найти груз после перцепки/перегрузки,<br>то укажите направления куда вы готовы доставить данный груз.<br>Вы можете указать как страны целиком, так и отдельные населенные пункты.<br>Вы можете добавить до 15 направлений.',

      have_cargo: 'Есть груз',
      shipping_type: 'Тип перевозки',
      vehicles_count: 'Количество машин',
      vehicle_body_type: 'Тип кузова',
      adr: 'ADR',
      weight: 'Вес (т)',
      volume: 'Объем (м³)',
      packing: 'Упаковка',
      cargo_name: 'Наименование груза',
      length: 'Длина (м)',
      width: 'Ширина (м)',
      height: 'Высота (м)',
      note: 'Текстовое примечание',
      rate: 'Ставка',
      payment_conditions: 'Условия оплаты',
      payment_terms: 'Срок оплаты (дней)',
      payment_method: 'Способ оплаты',

      cargo_length: 'Длина груза (м)',
      cargo_width: 'Ширина груза (м)',
      cargo_height: 'Высота груза (м)',

      recoupling_place: 'Место перецепки',
      reloading_place: 'Место перегрузки',
      recoupling_or_reloading_place: 'Место перецепки или перегрузки',

      uploading_place: 'Место загрузки',
      unloading_place: 'Место выгрузки',

      from: 'От',
      to: 'До',
    },

    view: {
      intercept_point: 'Место перецепки',
      reloading_point: 'Место перегрузки',
      uploading_place: 'Загрузка',
      unloading_place: 'Выгрузка',
      reloading_or_intercept_point: "Место перегрузки/перецепки",
      my_arrival_point: 'Куда доставить груз',
      your_arrival_point: 'Куда готовы ехать',
      shipping_type: 'Тип',
      length: 'Длина',
      width: 'Ширина',
      height: 'Высота',
      volume: 'Объем',
      weight: 'Вес',
      type: 'Тип',
      adr: 'ADR',
      packing: 'Упаковка',
      vehicle_body_type: 'Тип кузова',
      vehicles_count: 'Количество машин',

      matches: 'Подходит оферт: {count}',
      matches_open: 'Смотреть',
      full_match: 'Полное совпадение',
      partial_match: 'Частичное совпадение',

      more: 'Еще {count}',
      created_at: 'Создано ',

      share: 'Поделитесь этим предложением с вашими коллегами и партнерами',

      adr_present: 'Да',
      adr_absent: 'Нет'
    },
    describe_cargo: 'Опишите груз',
    note_description: 'Опишите что важно учесть',

    have_cargo: {
      title:  "У вас есть груз?",
      yes: 'Да',
      no: 'Нет (я предлагаю только транспорт)'
    },
    delete: {
      select_reason: 'Выберите причину удаления',
      confirm: 'Подтвердить',
      cancel: 'Отмена',
    },

    actions: {
      request: 'Откликнуться',
      not_interested: 'Не интересно',
      show: 'Смотреть',
      show_appropriate: 'Смотреть оферты',
      remove: 'Убрать',
      details: 'Детали',

      discard: 'Отмена',
      publish: 'Опубликовать',
      edit: "Редактировать",
    },

    filter: {
      hide_filter: 'Скрыть фильтр',
      show_filter: 'Открыть фильтр',
      clear: 'Очистить',

      all: 'Все',
      recoupling: 'Перецепки',
      reloading: 'Перегрузы',
      freight: 'Фрахты',
      vehicle_body_type: 'Тип кузова',
      adr: 'ADR',
      ftl: 'FTL',
      ltl: 'LTL',
      weight: 'Вес (т)',
      have_cargo: 'Только с грузом',
      from: 'От',
      to: 'До',
      volume: 'Объем (м³)',

      have_opposite_cargo: 'Только с грузом',
      full_match: 'Полное совпадение',
      partial_match: 'Частичное совпадение',

      intercept_point: 'Место перецепки',
      my_arrival_point: 'Куда доставить груз',
      your_arrival_point: 'Куда готовы ехать',

      uploading_place: 'Место загрузки',
      unloading_place: 'Место выгрузки',

      recoupling_place: 'Место перецепки',
      reloading_place: 'Место перегрузки',
      recoupling_or_reloading_place: 'Место перецепки или перегрузки',
    },

    contacts: {
      title: 'Контакты по заявке',
      not_specified: 'Не указано',

      non_registered: {
        title: 'Для того чтобы увидеть контактные данные необходимо зарегистрироваться.',
        wanna_register: 'Хотите зарегистрироваться?',
        confirm: 'Да'
      },
      fields: {
        name: 'Имя',
        company: 'Компания',
        site: 'Сайт',
        phone: 'Контактный телефон',
        email: 'Email',
        skype: 'Skype'
      },

      conditionsModal: {
        description: 'Чтобы просмотреть контакты, необходимо :<br>1. подтвердить вашу почту, на которую вы зарегистрировались в Roolz;<br>2. создать профиль вашей компании в разделе “Моя компания“.<br>Как только это будет выполнено, вы сможете просмотреть контакты.<br>'
      }
    },
    conditionsModal: {
      title: 'Создать предложение',
      description: 'Чтобы создать заявку, необходимо: <br>' +
        '1. подтвердить вашу почту, на которую вы зарегистрировались в Roolz;<br>' +
        // '2. cоздать профиль вашей компании в разделе "Моя компания". <br><br>' +
        'Как только это будет выполнено, вы сможете создавать заявки и находить встречные возможности.',
      close: 'Понятно'
    }
  },
  siteDescription: {
    title: 'Пора кооперироваться.<br>Наша бесплатная биржа грузов, перецепок и перегрузов к вашим услугам.',
    content: {
      1: 'Опубликуйте ваше предложение по грузу или машине.',
      2: 'Под вашу заявку будут подобраны встречные заявки.',
      3: 'Вы получаете новый груз по направлению, по которому вы можете работать. А ваш груз доставляется туда, куда вы и хотели.',
    },
    footer: 'Проверка контрагентов полностью на вашей стороне.'
  },

  emailConfirmation: {
    not_confirmed_message: 'Необходимо подтвердить ваш Email',
    confirm: 'Подтвердить',

    sentModal: {
      title: 'Подтверждение почты',
      sent: 'Мы отправили письмо на вашу почту\n\n {email} Пройдите по ссылке в письме для подтверждения почты.',
    }
  },

  notifications: {
    success: 'Успешно'
  },

  validation: {
    'password_conditions': 'Пароль должен содержать хоть одну букву и цифру\n' +
      'и быть длиной минимум в 8 символов',
    "email": "Должно быть валидным email-адресом",
    "required": "Обязательно для заполнения",
    // TODO write something
    "min": "",
    required_field: 'Обязательное поле'
  },
  mui: {
    autocomplete: {
      loading: 'Загрузка...',
      noOptions: 'Ничего не найдено'
    }
  },
  errors: {
    unsufficient_request: "Не удалось выполнить запрос. Попробуйте позже",
    server_error: "Произошла ошибка на сервере. Попробуйте позже",
    "Network error": "Проверьте подключение к интернету",

    wrong_credentials: "Неверный пароль"
  },

  actions: {
    save: 'Готово',
    back: 'Назад'
  },
  ui: {
    copy: 'Скопировать',
    copied: 'Скопировано!',

    specify: 'Поиск населенных пунктов...',
    select: 'Выбрать...',

    select_dates: 'Выбрать даты..',

    copy_link: 'Копировать ссылку'
  },
  confirmation: {
    sure: 'Вы уверены?',
    yes: 'Да',
    no: 'Нет'
  },
  measure: {
    tons: 'т',
    cubic_meters: 'м³',
    kilo: 'кг'
  },
  home_placeholders: {
    stock_not_found: 'Упс… Ничего не найдено',
    my_bids_not_found: ' Нет активных предложений, созданных вами',
    bid_not_found: 'Предложение больше не доступно'
  }
}
