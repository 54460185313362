<template>
  <router-view/>

  <!--
    dont unmount for ending animation playing when user logged in
  -->
  <AuthModal
    v-if="1 || !isAuthed"
    ref="authModal"
    key="authModal"
  />
</template>
<script>
import moment from 'moment'
import AuthModal from '@/components/modals/AuthModal'
import { bus, EVENTS } from "@/bus.js";
import {mapGetters} from "vuex";

export default {
  components: {
    AuthModal
  },
  created() {
    this.setTitle()
    this.setHtmlLang()
  },
  computed: {
    ...mapGetters('auth', ['isAuthed']),
    ...mapGetters('users', ['me'])
  },
  mounted() {
    bus.on(EVENTS.OPEN_AUTH, function() {
      this.$refs.authModal.open()
    }.bind(this))
  },
  watch: {
    '$i18n.locale'() {
      localStorage.setItem('lang', this.$i18n.locale)
      moment.locale(this.$i18n.locale)
      this.setHtmlLang()

      this.setTitle()
    }
  },
  methods: {
    setTitle() {
      document.title = 'Roolz | ' + this.$t('title')
    },
    setHtmlLang() {
      document.querySelector('html').setAttribute('lang', this.$i18n.locale)
    }

  }
}
</script>
<style lang="scss">
@import 'normalize-scss';
@include normalize();
@import 'vue-material-design-icons/styles.css';
@import 'element-plus/dist/index.css';
@import 'element-plus/theme-chalk/base.css';
@import 'ant-design-vue/dist/antd.css';
// @import 'typeface-roboto/index.css';

* {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 // text-align: center;
  box-sizing: border-box;
  // color: #2c3e50;
  //transition: all 0.3s;
}

.el-popper {
  padding: 5px !important;
}

a {
  text-decoration: none;
  color: #4778EE;
  font-weight: 500;
}

.slide-fade-enter-active {
  overflow: hidden;
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s;
}
.slide-fade-enter, .slide-fade-leave-to {
  height: 0;
}
@media(max-width: 660px) {
  .el-dialog {
    width: 97vw;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  transition: background-color 5000s ease-in-out 0s;
}
</style>
