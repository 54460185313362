import {difference} from "lodash";

function booleanToNum(bool) {
  return bool === true ? 1 : 0
}

export function prepareFormToSend(form, cleanFalsable = false) {
  let newForm = {}

  for(const i in form) {
    if(typeof form[i] === 'boolean' && (!cleanFalsable || form[i] === true)) {
      newForm[i] = booleanToNum(form[i])
      continue
    }

    if(!!form[i]) {
      newForm[i] = form[i]
    }
  }

  return newForm
}

export function preparePatchForm(form, currentModel) {
  // if(form.name !== undefined) {
  //   debugger
  // }
  let newForm = {}

  for(const i in form) {
    if(form[i] !== currentModel[i]) {
      if(!form[i] && !currentModel[i]) {
        continue
      }

      if(Array.isArray(form[i])) {
        const diff = difference(form[i], currentModel[i] ?? [])

        if(form[i].length === 0 || diff.length) {
          newForm[i] = form[i]
        }
        continue
      }

      if(typeof form[i] === 'object') {
        const diff = preparePatchForm(form[i], currentModel[i] ?? {})

        if(Object.keys(diff).length) {
          newForm[i] = diff
        }
        continue
      }

      newForm[i] = !form[i] && typeof form[i] !== 'number' ? null : form[i]
    }
  }

  return newForm
}
