<template>
  <DatePicker
    range
    :placeholder="$t('ui.select_dates')"
    format="DD.MM.YYYY"
    :lang="langObject"

    v-bind="$props"
  >
    <template #input="{ class: className, value, ...rest }">
      <ElInput
        class="datepicker-input"
        v-bind="rest"
        :modelValue="value"
        readonly
      />
    </template>
  </DatePicker>
</template>
<script>
import DatePicker from 'vue-datepicker-next2';
import { ElInput } from "element-plus";

export default {
  components: {
    DatePicker,
    ElInput
  },
  computed: {
    langObject() {
      const objects = {
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          },
          yearFormat: 'YYYY',
          monthFormat: 'MMM',
          monthBeforeYear: true,
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          },
          yearFormat: 'YYYY',
          monthFormat: 'MMM',
          monthBeforeYear: true,
        },
        pl: {
          formatLocale: {
            months: ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'],
            monthsShort: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
            weekdays: ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'],
            weekdaysShort: ['ndz', 'pon', 'wt', 'śr', 'czw', 'pt', 'sob'],
            weekdaysMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 4
          },
          yearFormat: 'YYYY',
          monthFormat: 'MMM',
          monthBeforeYear: true,
        }
      }

      return objects[this.$i18n.locale] ?? objects['en']
    }
  }
}
</script>
<style lang="scss">
@import 'vue-datepicker-next2/index.css';

.datepicker-input input {
  padding-right: 20px;
}
</style>
